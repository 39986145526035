import { combineReducers } from '@reduxjs/toolkit';
import { accountActivities } from '@copper/entities/accountActivities/accountActivities-reducer';
import { accounts } from '@copper/entities/accounts/accounts-reducer';
import { addressBook } from '@copper/entities/addressBook/addressBook-reducer';
import { adminUsers } from '@copper/entities/adminUsers/adminUsers-reducer';
import { announcements } from '@copper/entities/announcements/announcements-reducer';
import { apiKeys } from '@copper/entities/apiKeys/apiKeys-reducer';
import { approvalRequests } from '@copper/entities/approvalRequests/approvalRequests-reducer';
import { auth } from '@copper/entities/auth/auth-reducer';
import { billing } from '@copper/entities/billing/billing-reducer';
import { counterparties } from '@copper/entities/counterparties/counterparties-reducer';
import { currencyRates } from '@copper/entities/currencyRates/currencyRates-reducer';
import { custody } from '@copper/entities/custody/custody-reducer';
import { dictionaries } from '@copper/entities/dictionaries/dictionaries-reducer';
import { invoices } from '@copper/entities/invoices/invoices-reducer';
import { jurisdictions } from '@copper/entities/jurisdictions/jurisdictions-reducer';
import { legalEntities } from '@copper/entities/legalEntities/legalEntities-reducer';
import { lending } from '@copper/entities/lending/lending-reducer';
import { nfts } from '@copper/entities/nfts/nfts-reducer';
import { orders } from '@copper/entities/orders/orders-reducer';
import { organizations } from '@copper/entities/organizations/organizations-reducer';
import { oxygenPledges } from '@copper/entities/oxygen-pledges/pledges-reducer';
import { permissions } from '@copper/entities/permissions/permissions-reducer';
import { portfolios } from '@copper/entities/portfolios/portfolios-reducer';
import { reports } from '@copper/entities/reports/reports-reducer';
import { revault } from '@copper/entities/revault/revault-reducer';
import { rules } from '@copper/entities/rules/rules-reducer';
import { stakes } from '@copper/entities/stake/stake-reducer';
import { tokenAllowances } from '@copper/entities/tokenAllowances/tokenAllowances-reducer';
import { treasury } from '@copper/entities/treasury/treasury-reducer';
import { user } from '@copper/entities/user/user-reducer';
import { wallets } from '@copper/entities/wallets/wallets-reducer';
import { webhooks } from '@copper/entities/webhooks/webhooks-reducer';
export const rootReducer = combineReducers({
    auth,
    billing,
    user,
    wallets,
    accounts,
    portfolios,
    currencyRates,
    dictionaries,
    announcements,
    organizations,
    orders,
    counterparties,
    custody,
    reports,
    nfts,
    revault,
    rules,
    permissions,
    invoices,
    webhooks,
    accountActivities,
    addressBook,
    jurisdictions,
    legalEntities,
    tokenAllowances,
    stakes,
    approvalRequests,
    adminUsers,
    apiKeys,
    oxygenPledges,
    treasury,
    lending
});

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import cuid from 'cuid';
import useStateMachine from '@cassiozen/usestatemachine';
import { Summary } from '@copper/client/components/MigrateFunds/Summary';
import { selectCurrenciesEntities } from '@copper/entities/currencies/currencies-selector';
import { createOrder } from '@copper/entities/orders/orders-reducer';
import { selectWalletsByPortfolioId } from '@copper/entities/wallets/wallets-selector';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { sha256 } from '@noble/hashes/sha256';
import { bytesToHex } from '@noble/hashes/utils';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { MigrateFundsForm } from './Form';
import { Result } from './Result';
import { getMainCurrency, validationSchema } from './helpers';
import { usePortfolioAssets } from './hooks';
import s from './MigrateFunds.module.css';
const stepMap = {
    form: 1,
    summary: 2,
    result: 3
};
export const MigrateFunds = () => {
    const dispatch = useDispatch();
    const { setTopPanel } = useToolbar();
    const currenciesEntities = useSelector(selectCurrenciesEntities);
    const [transactions, setTransactions] = useState();
    const [total, setTotal] = useState('');
    const [migrationErrorMessage, setMigrationErrorMessage] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const form = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema),
        defaultValues: {}
    });
    const { getValues } = form;
    const [state, send] = useStateMachine({
        initial: 'form',
        states: {
            form: {
                on: { NEXT_STEP: 'summary' }
            },
            summary: {
                on: { NEXT_STEP: 'result', BACK_STEP: 'form', RESET: 'form' }
            },
            result: { on: { RESET: 'form' } }
        }
    });
    useEffect(() => {
        let subTitle = `Step ${stepMap[state.value]} of ${Object.keys(stepMap).length}`;
        if (state.value === 'result') {
            subTitle = migrationErrorMessage ? 'Failed' : 'Migration completed';
        }
        setTopPanel({
            title: 'Transfer funds',
            subTitle,
            onBack: state.value === 'summary' ? () => send({ type: 'BACK_STEP' }) : undefined
        });
    }, [state, send, setTopPanel, migrationErrorMessage]);
    const handleFormSubmit = () => {
        send({ type: 'NEXT_STEP' });
    };
    const walletsByPortfolioId = useSelector(selectWalletsByPortfolioId);
    const { origin, destination } = getValues();
    const assets = usePortfolioAssets(origin)?.filter((asset) => new BigNumber(asset.available).gt(0, 10)) ?? null;
    useEffect(() => {
        const totalValue = assets?.reduce((prev, current) => new BigNumber(prev).plus(current.balanceReporting).toFixed(), '0');
        setTotal(totalValue || '');
    }, [assets]);
    const submitMultipleOrders = async (orders) => {
        const response = await Promise.allSettled(orders.map((order) => dispatch(createOrder(order))));
        setTransactions(response);
    };
    const executeMultipleAssetsTransfer = async (passwordValue) => {
        if (!origin || !destination)
            return;
        const currenciesForTransfer = walletsByPortfolioId[origin.portfolioId]?.filter((asset) => new BigNumber(asset.available).gt(0, 10)) ?? [];
        if (currenciesForTransfer.length) {
            const data = currenciesForTransfer.map((currency) => {
                const mainCurrency = getMainCurrency(currenciesEntities, currency);
                return {
                    externalOrderId: cuid(),
                    baseCurrency: currency?.currency,
                    amount: currency?.available?.toString(),
                    portfolioId: origin?.portfolioId,
                    orderType: 'withdraw',
                    includeFeeInWithdraw: true,
                    toPortfolioId: destination?.portfolioId,
                    ...(mainCurrency && { mainCurrency }),
                    ...(passwordValue?.length && { masterPassword: bytesToHex(sha256(passwordValue)) })
                };
            });
            await submitMultipleOrders(data);
        }
        else {
            setMigrationErrorMessage('No funds available in source account');
        }
    };
    const handleFormSend = async (passwordValue) => {
        setIsSubmitting(true);
        setMigrationErrorMessage(undefined);
        try {
            await executeMultipleAssetsTransfer(passwordValue);
        }
        catch (err) {
            setMigrationErrorMessage(getErrorData(err).message);
        }
        finally {
            setIsSubmitting(false);
            send({ type: 'NEXT_STEP' });
        }
    };
    const handleDone = () => {
        form.reset();
        send('RESET');
    };
    return (_jsxs("div", { className: s.container, children: [state.value === 'form' && (_jsx(MigrateFundsForm, { form: form, assets: assets, total: total, onSubmit: handleFormSubmit })), state.value === 'summary' && (_jsx(Summary, { isSubmitting: isSubmitting, data: { ...getValues() }, assets: assets, onNext: handleFormSend, total: total })), state.value === 'result' && (_jsx(Result, { onDone: handleDone, transactions: transactions, assets: assets, errorMessage: migrationErrorMessage }))] }));
};

import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { requestBulkTranferTemplate, uploadBulkTransferTemplate } from '@copper/api/bulkTemplates';
import * as ordersApi from '@copper/api/orders';
import { getFile } from '@copper/api/users';
import { asyncPoll } from '@copper/utils/asyncPoll';
const mergeOrder = (currentOrder, newOrder) => ({
    ...currentOrder,
    ...newOrder,
    _embedded: { ...currentOrder._embedded, ...newOrder._embedded }
});
const initialState = {
    items: [],
    openedOrders: {}
};
export const fetchOrders = createAsyncThunk('orders/fetchOrders', async (params, thunkAPI) => {
    try {
        const response = await ordersApi.get(params);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createOrder = createAsyncThunk('orders/create', async (orderParams, thunkApi) => {
    try {
        const response = await ordersApi.create(orderParams);
        return response.data;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const fetchOrder = createAsyncThunk('orders/getById', async (orderId, thunkAPI) => {
    try {
        const response = await ordersApi.getById(orderId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const startCustodySettlement = createAsyncThunk('orders/startCustodySettlement', async (requestQueryObject, thunkAPI) => {
    try {
        const response = await ordersApi.startCustodySettlement(requestQueryObject.orderId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const startOtcSettlement = createAsyncThunk('orders/startSettlement', async (data, thunkAPI) => {
    try {
        const response = await ordersApi.startOtcSettlement(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const sendHalfSignedTx = createAsyncThunk('orders/sendHalfSignedTx', async (requestQueryObject, thunkAPI) => {
    try {
        const response = await ordersApi.sendHalfSignedTx(requestQueryObject.order, requestQueryObject.data, requestQueryObject.reject);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const sendFullSignedTx = createAsyncThunk('orders/sendFullSignedTx', async (requestQueryObject, thunkAPI) => {
    try {
        const response = await ordersApi.sendFullSignedTx(requestQueryObject.order, requestQueryObject.data, requestQueryObject.reject);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const cosignOrder = createAsyncThunk('orders/cosignOrder', async ({ orderId, masterPassword }, thunkAPI) => {
    try {
        const response = await ordersApi.cosign(orderId, {
            masterPassword
        });
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const cancelOrder = createAsyncThunk('orders/cancel-order', async (requestQueryObject, thunkAPI) => {
    try {
        const response = await ordersApi.cancel(requestQueryObject.orderId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const rejectOrder = createAsyncThunk('orders/reject-order', async ({ orderId, returnDetails }, thunkAPI) => {
    try {
        const response = await ordersApi.reject(orderId, returnDetails);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const downloadBulkTemplate = createAsyncThunk('orders/download-bulk-template', async ({ organizationId, userId }, thunkApi) => {
    try {
        const { data: { fileId } } = await requestBulkTranferTemplate({ organizationId });
        const fileData = await asyncPoll(() => getFile(userId, fileId, { responseType: 'blob' }), 1000, 30000);
        return {
            fileId,
            fileData
        };
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const uploadBulkTemplate = createAsyncThunk('orders/upload-bulk-template', async (file, thunkApi) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        const { data: { orders = [] } } = await uploadBulkTransferTemplate(formData);
        return orders;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const setOrderAddresses = createAsyncThunk('orders/set-order-addresses', async ({ orderId, addressData }, thunkApi) => {
    try {
        const response = await ordersApi.setOrderAddresses({ orderId, addressData });
        return response.data;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const acceptOrderAddresses = createAsyncThunk('orders/accept-order-addresses', async ({ orderId, accepted }, thunkApi) => {
    try {
        const response = await ordersApi.acceptOrderAddresses({
            orderId,
            acceptedData: { accepted }
        });
        return response.data;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const approveOtcOrder = createAsyncThunk('orders/approve-order-otc', async ({ order, portfolioIdOverride }, thunkApi) => {
    try {
        const response = await ordersApi.approveOtc({
            orderId: order.orderId,
            status: order.status,
            portfolioIdOverride
        });
        return response.data;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const approveOrder = createAsyncThunk('orders/approve-order', async ({ orderId }, thunkApi) => {
    try {
        const response = await ordersApi.approve({
            orderId
        });
        return response.data;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const addSender = createAsyncThunk('orders/add-sender', async ({ orderId, senderId }, thunkApi) => {
    try {
        const response = await ordersApi.addSender(orderId, senderId);
        return response.data;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        resetOrders(state) {
            state.items = [];
        },
        setOrders(state, { payload }) {
            state.items = payload;
        },
        updateOrders(state, { payload: { data, options } }) {
            const updatedOrders = data;
            state.items = state.items.map((oldOrder) => {
                const updatedOrder = updatedOrders.find((order) => order.orderId === oldOrder.orderId);
                return updatedOrder ? mergeOrder(oldOrder, updatedOrder) : oldOrder;
            });
            state.openedOrders = Object.values(state.openedOrders).reduce((acc, order) => {
                const updatedOrder = updatedOrders.find((newOrder) => newOrder.orderId === order.orderId);
                if (updatedOrder)
                    return {
                        ...acc,
                        [order.orderId]: mergeOrder(order, updatedOrder)
                    };
                return acc;
            }, state.openedOrders);
            if (!options?.skipInsert) {
                const newOrders = updatedOrders.filter((order) => !state.items.find((oldOrder) => oldOrder.orderId === order.orderId));
                state.items = [...newOrders, ...state.items];
            }
        }
    },
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchOrders.fulfilled, (state, { payload }) => {
            state.items = payload.orders;
        });
        addCase(fetchOrder.fulfilled, (state, { payload }) => {
            state.openedOrders = { ...state.openedOrders, [payload.orderId]: payload };
        });
        addMatcher(isAnyOf(setOrderAddresses.fulfilled, acceptOrderAddresses.fulfilled, startCustodySettlement.fulfilled, startOtcSettlement.fulfilled, approveOtcOrder.fulfilled, approveOrder.fulfilled, cancelOrder.fulfilled, rejectOrder.fulfilled, addSender.fulfilled), (state, { payload: order }) => {
            const existingOrder = state.items.find((existingOrder) => existingOrder.orderId === order.orderId);
            if (state.openedOrders[order.orderId]) {
                const currentOrder = state.openedOrders[order.orderId];
                state.openedOrders = {
                    ...state.openedOrders,
                    [order.orderId]: mergeOrder(currentOrder, order)
                };
            }
            if (existingOrder) {
                state.items = state.items.map((oldOrder) => oldOrder.orderId === order.orderId ? mergeOrder(oldOrder, order) : oldOrder);
            }
            else {
                state.items = [...state.items, order];
            }
        });
    }
});
export const { resetOrders, updateOrders, setOrders } = ordersSlice.actions;
export const orders = ordersSlice.reducer;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { ClearloopDelegateController } from '@copper/components/Counterparty/ClearloopDelegateController';
import { ConnectionControls } from '@copper/components/Counterparty/ConnectionControls';
import { CounterpartyLogo } from '@copper/components/Counterparty/CounterpartyNetworkCard/CounterpartyLogo';
import { NetworkActionsController } from '@copper/components/Counterparty/NetworkActionsController';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { getImageSrcById } from '@copper/helpers';
import s from './CounterpartyNetworkCard.module.css';
const CounterpartyNetworkCard = ({ counterparty, readOnly, form, view = 'default' }) => {
    const navigate = useNavigate();
    const { organizationType: currentOrganizationType } = useSelector(selectCurrentOrganization);
    const { backgroundImageId = 'counterparties/backgrounds/defaults/1-v2.png', counterpartyName, networkTag: counterpartyNickname, counterpartyDescription, counterpartyId } = counterparty;
    const counterpartyConnection = counterparty._embedded?.counterpartyConnection;
    const getLogoSize = () => {
        if (form === 'double')
            return '2xl';
        if (form === 'xs')
            return 'l';
        if (form === 'preview')
            return 'l';
        if (form === 's')
            return 'xs';
        return form;
    };
    const isNetworkActionsControllerVisible = counterpartyConnection?.requestStatus === 'accepted' && currentOrganizationType !== 'lite';
    const handleNavigation = (event) => {
        event.stopPropagation();
        !readOnly && navigate(`/network/counterparties/${counterpartyId}`);
    };
    return (_jsxs("div", { className: cn(s.cardContainer, s[`cardContainer_${form}`], s[`cardContainer_view_${view}`], readOnly && s.containerReadOnly), onClick: handleNavigation, onKeyDown: (event) => {
            if (event.key === 'Enter' || event.key === ' ') {
                handleNavigation(event);
            }
        }, "data-testid": "button-network-card", children: [['m', 'preview'].includes(form) && (_jsx("div", { className: cn(s.cardHeaderBackground, form === 'preview' && s.cardHeaderBackground_preview), style: {
                    backgroundImage: `url(${getImageSrcById(backgroundImageId)})`
                } })), !['s', 'preview'].includes(form) && (_jsx("div", { className: cn(s.cardBlurredBackground, s[`cardBlurredBackground_${form}`]), style: {
                    backgroundImage: `url(${getImageSrcById(backgroundImageId)})`
                } })), _jsxs("div", { className: cn(s.cardContent, s[`cardContent_${form}`]), children: [_jsxs("div", { className: cn(s[`logoContainer_${form}`]), children: [_jsx(CounterpartyLogo, { imageId: counterparty.imageId, counterpartyName: counterparty.counterpartyName, size: getLogoSize() }), ['l', 'double'].includes(form) && (_jsx("div", { className: s.featuredCardBackground, style: {
                                    backgroundImage: `url(${getImageSrcById(backgroundImageId)})`
                                } })), form === 'xs' && (_jsxs("div", { className: s.cardTextSmall, children: [_jsx("p", { className: cn(s.counterpartyName, s[`counterpartyName_${form}`]), children: counterpartyName }), counterpartyNickname && (_jsxs("p", { className: cn(s.counterpartyAlias, s[`counterpartyAlias_${form}`]), children: ["@", counterpartyNickname] }))] }))] }), form !== 'xs' && (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.cardText, children: [_jsx("p", { className: cn(s.counterpartyName, s[`counterpartyName_${form}`]), children: counterpartyName }), counterpartyNickname && (_jsxs("p", { className: cn(s.counterpartyAlias, s[`counterpartyAlias_${form}`]), children: ["@", counterpartyNickname] })), form !== 'preview' && (_jsx("p", { className: cn(s.counterpartyDescription, s[`counterpartyDescription_${form}`]), children: counterpartyDescription }))] }), form !== 'preview' && (_jsxs("div", { className: s.cardActions, children: [_jsx(ClearloopDelegateController, { counterparty: counterparty, size: form === 's' ? 's' : 'm', readOnly: readOnly, view: "ghost" }), readOnly || isNetworkActionsControllerVisible ? (_jsx(NetworkActionsController, { counterparty: counterparty, size: form === 's' ? 's' : 'm', readOnly: readOnly, view: "ghost" })) : (_jsx(ConnectionControls, { counterparty: counterparty, view: "ghost", size: form === 's' ? 's' : 'm' }))] }))] }))] })] }));
};
export { CounterpartyNetworkCard };

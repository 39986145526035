import { jsx as _jsx } from "react/jsx-runtime";
import { IconAlert, IconCheckMark, IconClock, IconCrossOutline } from '@copper/ui-kit';
export const checkIsOwnerRejected = (owner) => {
    const { verificationStatus } = owner;
    return verificationStatus === 'rejected';
};
export const checkIsOwnerVerified = (owner) => {
    const { verificationStatus } = owner;
    return ['not-required', 'verified'].includes(verificationStatus);
};
export const getInvestigationStatusAsObject = (status) => {
    switch (status) {
        case 'approved':
        case 'completed':
        case 'not-required':
            return {
                color: 'green',
                status: 'Active'
            };
        case 'information-missing':
            return {
                color: 'orange',
                status: 'Unverified'
            };
        case 'alerting':
        case 'processing':
            return {
                color: 'orange',
                status: 'Processing'
            };
        case 'rejected':
            return {
                color: 'red',
                status: 'Rejected'
            };
        default:
            return {
                color: 'base',
                status: status ?? ''
            };
    }
};
export const getVerificationStatusAsObject = (status) => {
    switch (status) {
        case 'not-required':
        case 'verified':
            return {
                color: 'green',
                status: 'Active'
            };
        case 'new':
            return {
                color: 'orange',
                status: 'Unverified'
            };
        case 'processing':
            return {
                color: 'orange',
                status: 'Processing'
            };
        case 'rejected':
            return {
                color: 'red',
                status: 'Rejected'
            };
        default:
            return {
                color: 'base',
                status: status ?? ''
            };
    }
};
export const mapStatusToIcon = (status) => {
    switch (status) {
        case 'approved':
        case 'completed':
        case 'not-required':
        case 'verified':
            return _jsx(IconCheckMark, {});
        case 'alerting':
        case 'information-missing':
        case 'processing':
            return _jsx(IconClock, {});
        case 'rejected':
            return _jsx(IconCrossOutline, {});
        case 'new':
            return _jsx(IconAlert, { type: "warning" });
    }
};
export const checkIsOwnershipVerified = (ownerId, address) => {
    const ownershipStatus = address?._cryptoAddressEmbedded?.ownership?.ownershipStatus?.find((ownership) => ownership.ownerId === ownerId);
    return ownershipStatus?.ownershipStatus === 'verified';
};
export const isOwnerMissingInformation = (owner) => owner?.investigationStatus === 'information-missing';

import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import s from './EmptyList.module.css';
export const EmptyList = ({ message, type = 'data' }) => {
    const emptyMessage = (type === 'search' && 'No account with this name found') ||
        (type === 'favorites' && 'No starred accounts yet') ||
        message ||
        'No accounts yet';
    return _jsx("div", { className: s.empty, children: emptyMessage });
};

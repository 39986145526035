import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import useStateMachine from '@cassiozen/usestatemachine';
import { ClearloopForm } from '@copper/components/AddAccountPopup/ClearloopForm';
import { ClearloopResult } from '@copper/components/AddAccountPopup/ClearloopResult';
import { Popup, PopupHeader } from '@copper/ui-kit';
import { PopupContent } from '@copper/ui-kit/Popup/PopupContent';
const AddClearloopPortfolioPopup = ({ onBack, parentPortfolio }) => {
    const [connectedPortfolio, setConnectedPortfolio] = useState();
    const [state, send] = useStateMachine({
        initial: 'addCLPortfolio',
        states: {
            addCLPortfolio: {
                on: {
                    RESULT: 'clearloopResult'
                }
            },
            clearloopResult: {}
        }
    });
    return (_jsxs(Popup, { onClose: onBack, children: [_jsx(PopupHeader, { title: "Connect ClearLoop account", onBack: state.value === 'addCLPortfolio' ? onBack : undefined }), state.value === 'addCLPortfolio' && (_jsx(ClearloopForm, { predefinedParentPortfolio: parentPortfolio, onNext: (portfolio) => {
                    setConnectedPortfolio(portfolio);
                    send('RESULT');
                } })), state.value === 'clearloopResult' && (_jsx(PopupContent, { children: _jsx(ClearloopResult, { onSuccess: onBack, portfolioId: connectedPortfolio?.portfolioId }) }))] }));
};
export { AddClearloopPortfolioPopup };

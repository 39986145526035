import { useWebsocketContext } from '@copper/helpers/websocket-context';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSkipFirstRender } from './useSkipFirstRender';
export const useSocketsSubscribe = ({ uri, params, active = true, onUpdate, onError, resolveDelay, handleLoader = () => { } }) => {
    const { createSubscription, removeSubscription } = useWebsocketContext();
    const currentSubscriptionId = useRef('');
    const currentSubscriptionPromiseRef = useRef(new Promise(() => { }));
    const [debouncedParams, setDebouncedParams] = useState(params);
    const debouncedUpdateParams = useCallback(debounce((params) => setDebouncedParams(params), 50), []);
    useSkipFirstRender(() => debouncedUpdateParams(params), [params]);
    const unsubscribe = () => {
        if (currentSubscriptionId.current) {
            removeSubscription(currentSubscriptionId.current);
            currentSubscriptionId.current = '';
        }
    };
    const subscribe = () => {
        handleLoader(true);
        const { subscriptionId, subscriptionPromise } = createSubscription({
            uri,
            params,
            onUpdate,
            onError,
            resolveDelay
        });
        currentSubscriptionPromiseRef.current = subscriptionPromise;
        subscriptionPromise.then(() => {
            handleLoader(false);
        });
        currentSubscriptionId.current = subscriptionId;
    };
    useEffect(() => {
        if (!active) {
            unsubscribe();
            return;
        }
        unsubscribe();
        subscribe();
        // return unsubscribe for unmount case
        return unsubscribe;
    }, [uri, JSON.stringify(debouncedParams), active]);
    const forceResubscribe = () => {
        unsubscribe();
        subscribe();
    };
    return {
        forceResubscribe,
        subscriptionId: currentSubscriptionId?.current,
        subscriptionPromise: currentSubscriptionPromiseRef.current
    };
};

import * as dictionariesApi from '@copper/api/dictionaries';
export const getNetworks = () => dictionariesApi.getNetworks().then((response) => response.data.networks);
export const getNetwork = (data) => dictionariesApi.getNetwork(data).then((response) => response.data);
export const getTokens = () => dictionariesApi.getTokens().then((response) => response.data.tokens);
export const getToken = (data) => dictionariesApi.getToken(data).then((response) => response.data);
export const getNetworkTokens = () => dictionariesApi.getNetworkTokens().then((response) => response.data.networkTokens);
export const getNetworkTokensByQuery = (data) => dictionariesApi.getNetworkTokensByQuery(data).then((response) => response.data.networkTokens);
export const createTokenModification = (data) => dictionariesApi.createTokenModification(data).then((response) => response.data);
export const createNetworkTokenModification = (data) => dictionariesApi.createNetworkTokenModification(data).then((response) => response.data);
export const getDepositBankAccounts = (params) => dictionariesApi
    .getDepositBankAccounts(params)
    .then((response) => response.data.depositBankAccounts);

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import useStateMachine from '@cassiozen/usestatemachine';
import { SwitchStateRow } from '@copper/components/Forms/SwitchStateRow';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { selectPortfolioEntities } from '@copper/entities/portfolios/portfolios-selector';
import { createRebalancingRules } from '@copper/entities/rules/rules-reducer';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { BalanceOnAccountForm } from './BalanceOnAccountForm';
import { Result } from './Result';
import { Summary } from './Summary';
import { UntilTheBalanceReachesForm } from './UntilTheBalacneReachesForm';
import { validationSchema } from './helpers';
import s from './Rules.module.css';
const formId = 'rules-form';
const stepsNumbers = {
    balanceOnAccount: 1,
    untilTheBalanceReached: 2,
    summary: 3
};
export const Rules = () => {
    const [formResult, setFormResult] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const currentOrganization = useSelector(selectCurrentOrganization);
    const { openSnackbar } = useSnackBar();
    const { setTopPanel } = useToolbar();
    const { route } = useRoutingContext();
    const portfolios = useSelector(selectPortfolioEntities);
    const defaultPortfolio = route?.name === 'transfer' ? portfolios[route?.data?.portfolioId ?? ''] : undefined;
    const [state, send] = useStateMachine({
        initial: 'balanceOnAccount',
        states: {
            balanceOnAccount: {
                on: { NEXT_STEP: 'untilTheBalanceReached', RESET: 'balanceOnAccount' }
            },
            untilTheBalanceReached: {
                on: { NEXT_STEP: 'summary', BACK_STEP: 'balanceOnAccount', RESET: 'balanceOnAccount' }
            },
            summary: {
                on: { BACK_STEP: 'untilTheBalanceReached', RESET: 'balanceOnAccount' }
            }
        }
    });
    const form = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ruleType: 'below',
            balanceType: 'available',
            amountThreshold: '',
            targetBalanceLimit: '',
            transferMinBalance: '',
            portfolio: defaultPortfolio
        }
    });
    const { handleSubmit, getValues, control, setValue, formState: { errors, isDirty }, clearErrors, trigger, watch } = form;
    const values = getValues();
    const dispatch = useDispatch();
    const onSubmit = async (values) => {
        setIsSubmitting(true);
        const { currency, portfolio, mainCurrency, transferPortfolio, amountBy, ...dataProps } = values;
        const requestData = {
            ...dataProps,
            currency: currency?.currency || '',
            portfolioId: portfolio?.portfolioId || '',
            transferPortfolioId: transferPortfolio?.portfolioId || '',
            organizationId: currentOrganization.organizationId,
            mainCurrency: mainCurrency || currency?.mainCurrencies[0],
            ...(amountBy === 'quote' && { quoteCurrency: 'USD' })
        };
        try {
            await dispatch(createRebalancingRules(requestData)).unwrap();
            setFormResult('success');
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setIsSubmitting(false);
        }
    };
    const onNext = async () => {
        switch (state.value) {
            case 'balanceOnAccount':
                await trigger([
                    'portfolio',
                    'ruleType',
                    'currency',
                    'amountThreshold',
                    'balanceType',
                    'mainCurrency',
                    'transferPortfolio',
                    'transferMinBalance'
                ]);
                break;
            case 'untilTheBalanceReached':
                await trigger(['targetBalanceLimit']);
                break;
        }
        if (!Object.keys(errors).length && isDirty) {
            clearErrors();
            send('NEXT_STEP');
        }
    };
    let formView;
    const resetForm = () => {
        form.reset();
        setFormResult(undefined);
        send('RESET');
    };
    if (formResult) {
        switch (formResult) {
            case 'success':
            default:
                formView = _jsx(Result, { resetForm: resetForm });
        }
    }
    else {
        switch (state.value) {
            case 'untilTheBalanceReached':
                formView = (_jsx(UntilTheBalanceReachesForm, { values: values, control: control, errors: errors, watch: watch }));
                break;
            case 'summary':
                formView = _jsx(Summary, { values: values, watch: watch });
                break;
            case 'balanceOnAccount':
            default:
                formView = (_jsx(BalanceOnAccountForm, { control: control, errors: errors, values: values, watch: watch }));
        }
    }
    useEffect(() => {
        let subTitle = `Step ${stepsNumbers[state.value]} of 3'`;
        if (formResult) {
            subTitle = 'Rule created';
        }
        setTopPanel({
            title: 'Transfer funds',
            subTitle,
            onBack: state.value !== 'balanceOnAccount' && !formResult
                ? () => {
                    clearErrors();
                    send('BACK_STEP');
                }
                : undefined
        });
    }, [state, formResult, setTopPanel, clearErrors, send]);
    useEffect(() => {
        if (values.ruleType !== 'below') {
            setValue('transferMinBalance', undefined);
        }
    }, [values.ruleType, setValue]);
    useEffect(() => {
        if (!(values.currency && values.currency.mainCurrencies.length > 1)) {
            setValue('mainCurrency', undefined);
        }
    }, [values.currency, setValue]);
    const onStepSubmit = (e) => {
        if (state.value !== 'summary') {
            onNext();
        }
        e.preventDefault();
    };
    return (_jsxs("div", { className: s.container, children: [_jsx("div", { className: cn(s.formContainer, { [s.formContainerFullWidth]: formResult }), children: _jsx(FormProvider, { ...form, children: _jsx("form", { id: formId, onSubmit: (e) => onStepSubmit(e), children: formView }) }) }), !formResult &&
                (state.value !== 'summary' ? (_jsx(SwitchStateRow, { onClick: onNext, type: "button", text: "Next" })) : (_jsx(SwitchStateRow, { form: formId, onClick: handleSubmit(onSubmit), loading: isSubmitting, testId: "transfer-form-submit", text: "Create" })))] }));
};

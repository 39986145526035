import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import { Tooltip } from '@copper/ui-kit';
import s from './ChoiceCard.module.css';
const ChoiceCard = ({ description, title, onClick, tooltipLabel, leftSection, rightSection, wrapperModifier = 'normal', testId }) => {
    const Component = onClick ? 'button' : 'div';
    return (_jsx("div", { className: cn(s.wrapper, s[`wrapper_${wrapperModifier}`]), children: _jsxs(Component, { className: cn(s.item, { [s.active]: Boolean(onClick) }), "data-testid": testId, onClick: onClick, children: [_jsx("div", { className: s.leftIcon, children: leftSection }), _jsxs("div", { className: s.description, children: [_jsx("div", { className: s.title, children: title }), _jsx("div", { className: s.text, children: description })] }), _jsx("div", { className: s.action, children: _jsx(Tooltip, { label: tooltipLabel, children: rightSection }) })] }) }));
};
export { ChoiceCard };

import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as counterpartiesApi from '@copper/api/counterparties';
export const fetchCounterparties = createAsyncThunk('counterparties/fetchAllCounterparties', async (_, thunkAPI) => {
    try {
        const response = await counterpartiesApi.getCounterparties();
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchCounterpartyById = createAsyncThunk('counterparties/fetchCounterpartyById', async ({ organizationId, counterpartyId }, thunkAPI) => {
    try {
        const response = await counterpartiesApi.getCounterpartyById({
            organizationId,
            counterpartyId
        });
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchCounterpartiesByOrg = createAsyncThunk('counterparties/fetchCounterpartiesByOrg', async ({ organizationId, params }, thunkAPI) => {
    try {
        const response = await counterpartiesApi.getCounterpartiesByOrg(organizationId, params);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createCounterpartyConnection = createAsyncThunk('counterparties/createCounterpartyConnection', async ({ organizationId, data }, thunkAPI) => {
    try {
        await counterpartiesApi.createCounterpartyConnection({
            organizationId,
            data
        });
        data.receiverCounterpartyId &&
            thunkAPI.dispatch(fetchCounterpartyById({ organizationId, counterpartyId: data.receiverCounterpartyId }));
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const cancelCounterpartyConnection = createAsyncThunk('counterparties/cancelCounterpartyConnection', async ({ organizationId, counterpartyId, connectionId }, thunkAPI) => {
    try {
        await counterpartiesApi.cancelCounterpartyConnection(organizationId, connectionId);
        return counterpartyId;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const acceptCounterpartyConnection = createAsyncThunk('counterparties/acceptCounterpartyConnection', async ({ organizationId, data }, thunkAPI) => {
    try {
        const response = await counterpartiesApi.acceptCounterpartyConnection({
            organizationId,
            data
        });
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const declineCounterpartyConnection = createAsyncThunk('counterparties/declineCounterpartyConnection', async ({ organizationId, connectionId }, thunkAPI) => {
    try {
        const response = await counterpartiesApi.declineCounterpartyConnection(organizationId, connectionId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const deleteCounterpartyConnection = createAsyncThunk('counterparties/deleteCounterpartyConnection', async ({ organizationId, counterpartyId, data }, thunkAPI) => {
    try {
        await counterpartiesApi.deleteCounterpartyConnection({
            organizationId,
            data
        });
        return counterpartyId;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateCounterpartyConnectionPortfolio = createAsyncThunk('counterparties/updateCounterpartyConnectionPortfolio', async ({ organizationId, counterpartyId, connectionId, data }, thunkAPI) => {
    try {
        await counterpartiesApi.updateCounterpartyConnectionPortfolio({
            organizationId,
            connectionId,
            data
        });
        thunkAPI.dispatch(fetchCounterpartyById({ organizationId, counterpartyId }));
        return data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const inviteCounterparty = createAsyncThunk('counterparties/inviteCounterparty', async ({ organizationId, data }, thunkAPI) => {
    try {
        const response = await counterpartiesApi.inviteCounterparty({
            organizationId,
            data
        });
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const uploadCounterpartyLogo = createAsyncThunk('counterparties/uploadCounterpartyLogo', async ({ organizationId, counterpartyId, formData }, thunkAPI) => {
    try {
        const response = await counterpartiesApi.uploadCounterpartyLogo({
            organizationId,
            counterpartyId,
            formData
        });
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const deleteCounterpartyLogo = createAsyncThunk('counterparties/deleteCounterpartyLogo', async ({ organizationId, counterpartyId }, thunkAPI) => {
    try {
        return await counterpartiesApi.deleteCounterpartyLogo({
            organizationId,
            counterpartyId
        });
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateCounterpartyNetworkTag = createAsyncThunk('counterparties/updateCounterpartyTag', async (params, thunkAPI) => {
    try {
        const res = await counterpartiesApi.updateCounterpartyNetworkTag(params);
        return res.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const uploadCounterpartyBackground = createAsyncThunk('counterparties/uploadCounterpartyBackground', async ({ organizationId, counterpartyId, formData }, thunkAPI) => {
    try {
        const response = await counterpartiesApi.uploadCounterpartyBackground({
            organizationId,
            counterpartyId,
            formData
        });
        return response?.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const deleteCounterparty = createAsyncThunk('counterparties/deleteCounterparty', async ({ organizationId, counterpartyId }, thunkAPI) => {
    try {
        return await counterpartiesApi.deleteCounterparty({
            organizationId,
            counterpartyId
        });
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateCounterpartyById = createAsyncThunk('counterparties/updateCounterparty', async ({ organizationId, counterpartyId, data }, thunkAPI) => {
    try {
        const response = await counterpartiesApi.updateCounterparty({
            organizationId,
            counterpartyId,
            data
        });
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateRelatedCounterparties = createAsyncThunk('counterparties/updateRelatedCounterparties', async ({ organizationId, counterpartyId, data }, thunkAPI) => {
    try {
        const response = await counterpartiesApi.updateRelatedCounterparties({
            organizationId,
            counterpartyId,
            data
        });
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const counterpartiesSlice = createSlice({
    name: 'counterparties',
    initialState: {
        entities: {}
    },
    reducers: {},
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchCounterpartyById.fulfilled, (state, action) => {
            const counterparty = action.payload;
            state.entities = { ...state.entities, [counterparty.counterpartyId]: counterparty };
        });
        addCase(fetchCounterpartiesByOrg.fulfilled, (state, action) => {
            const counterparties = action.payload.counterparties.reduce((counterparties, counterparty) => ({
                ...counterparties,
                [counterparty.counterpartyId]: counterparty
            }), {});
            state.entities = counterparties;
        });
        addCase(fetchCounterpartiesByOrg.rejected, (state) => {
            state.entities = {};
        });
        addCase(fetchCounterparties.fulfilled, (state, action) => {
            action.payload.counterparties.forEach((counterparty) => (state.entities = { ...state.entities, [counterparty.counterpartyId]: counterparty }));
        });
        addCase(updateCounterpartyNetworkTag.fulfilled, (state, action) => {
            const counterparty = action.payload;
            state.entities = { ...state.entities, [counterparty.counterpartyId]: counterparty };
        });
        addCase(acceptCounterpartyConnection.fulfilled, (state, action) => {
            const connection = action.payload;
            const counterparty = state.entities[connection.requestorCounterpartyId];
            const counterpartyId = counterparty?.counterpartyId;
            if (counterpartyId) {
                state.entities = {
                    ...state.entities,
                    [counterpartyId]: {
                        ...counterparty,
                        _embedded: {
                            counterpartyConnection: connection
                        }
                    }
                };
            }
        });
        addCase(declineCounterpartyConnection.fulfilled, (state, action) => {
            const connection = action.payload;
            const counterparty = state.entities[connection.requestorCounterpartyId];
            const counterpartyId = counterparty?.counterpartyId;
            if (counterpartyId) {
                state.entities = {
                    ...state.entities,
                    [counterpartyId]: {
                        ...counterparty,
                        _embedded: {
                            counterpartyConnection: connection
                        }
                    }
                };
            }
        });
        addCase(deleteCounterparty.fulfilled, (state, action) => {
            const { counterpartyId } = action.meta.arg;
            delete state.entities[counterpartyId];
        });
        addCase(cancelCounterpartyConnection.fulfilled, (state, action) => {
            const countepartyId = action.payload;
            delete state.entities[countepartyId]?._embedded?.counterpartyConnection;
        });
        addCase(deleteCounterpartyConnection.fulfilled, (state, action) => {
            const countepartyId = action.payload;
            delete state.entities[countepartyId]?._embedded?.counterpartyConnection;
        });
        addMatcher(isAnyOf(updateCounterpartyById.fulfilled), (state, action) => {
            const { counterpartyId } = action.payload;
            state.entities = {
                ...state.entities,
                [counterpartyId]: {
                    ...action.payload
                }
            };
        });
    }
});
const { reducer } = counterpartiesSlice;
export const counterparties = reducer;

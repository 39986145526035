import { createSlice } from '@reduxjs/toolkit';
import { normalizeCurrencyRates } from '@copper/entities/currencyRates/currencyRates-normalize';
export const currencyRatesSlice = createSlice({
    name: 'currencyRates',
    initialState: {},
    reducers: {
        setCurrencyRates: (_, { payload }) => normalizeCurrencyRates(payload),
        updateCurrencyRates: (state, { payload }) => {
            for (const marketPrice of payload) {
                state[marketPrice.baseCurrency] = marketPrice.price;
            }
        }
    }
});
export const { setCurrencyRates, updateCurrencyRates } = currencyRatesSlice.actions;
export const currencyRates = currencyRatesSlice.reducer;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useBulkOrder } from '@copper/client/components/BulkOrderTools';
import { setCssProperty } from '@copper/utils/styles';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { BulkTransferPopup } from './Popup';
import { Result } from './Result';
import { UploadStep } from './UploadStep';
import s from './Bulk.module.css';
const TransferBulk = () => {
    const { modalContext } = useBulkOrder();
    const { setTopPanel } = useToolbar();
    const [view, setView] = useState('data');
    useEffect(() => {
        setCssProperty('--toolbar-extend-width', '316px');
        return () => setCssProperty('--toolbar-extend-width', '0px');
    }, []);
    useEffect(() => {
        let subTitle = 'Single step';
        if (view === 'result') {
            subTitle = 'Settlement instruction created';
        }
        setTopPanel({
            title: 'Transfer funds',
            subTitle
        });
    }, [view]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.container, children: [(view === 'data' || view === 'recover') && (_jsx(UploadStep, { initialState: view === 'recover' ? 'parsedData' : undefined, onSuccess: () => setView('result') })), view === 'result' && _jsx(Result, { onDone: (view) => setView(view) })] }), modalContext && _jsx(BulkTransferPopup, {})] }));
};
export { TransferBulk };

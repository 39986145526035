import { request } from '@copper/utils';
export const create = (data) => request.post('/wallets', data);
export const get = (params) => request.get('/wallets', { params });
export const updateDepositAddresses = (walletId, data) => request.patch(`/wallets/${walletId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.update-deposit-addresses+json'
    }
});
export const approveDepositAddress = (walletId, data) => request.patch(`/wallets/${walletId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.approve-deposit-address+json'
    }
});
export const declineDepositAddress = (walletId, data) => request.patch(`/wallets/${walletId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.decline-deposit-address+json'
    }
});
export const removeDepositAddress = (walletId, data) => request.patch(`/wallets/${walletId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.remove-deposit-address+json'
    }
});
export const uploadWalletShardKey = (walletId, data) => request.patch(`/wallets/${walletId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.upload-wallet-shard-key+json'
    }
});

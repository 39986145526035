import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import cn from 'classnames';
import useStateMachine from '@cassiozen/usestatemachine';
import { ButtonIcon, Collapse, IconArrowBack, IconArrowDown } from '@copper/ui-kit';
import { TopicCard } from './TopicCard';
import { topics } from './topics';
import s from './Topics.module.css';
export const Topics = () => {
    const [state, send] = useStateMachine({
        initial: 'topic',
        states: {
            topic: {
                on: {
                    NEXT_STEP: 'qa'
                }
            },
            qa: {
                on: {
                    BACK_STEP: 'topic'
                }
            }
        }
    });
    const [currentTopic, setCurrentTopic] = useState(topics[0]);
    const currentSubtopics = currentTopic?.qa;
    const handleTopicClick = (topic) => {
        setCurrentTopic(topic);
        send('NEXT_STEP');
    };
    const onBack = () => {
        send('BACK_STEP');
    };
    return (_jsxs("div", { className: s.container, children: [state.value === 'topic' &&
                topics.map((topic) => (_jsx(TopicCard, { title: topic.title, onClick: () => handleTopicClick(topic) }, topic.id))), state.value === 'qa' && (_jsxs("div", { className: s.subtopicContainer, children: [_jsxs("div", { className: s.subtopicTop, children: [_jsx(ButtonIcon, { className: s.backButton, onClick: () => onBack(), children: _jsx(IconArrowBack, {}) }), currentTopic.title] }), currentSubtopics?.map((qa) => (_jsx("div", { className: s.collapsingContainer, children: _jsx(Collapse, { renderToggle: (isOpen, onToggleCollapse) => (_jsxs("button", { type: "button", className: s.collapsingTitle, onClick: onToggleCollapse, children: [_jsx("div", { className: s.subtopicTitle, children: qa.question }), _jsx("span", { className: cn(s.arrow, { [s.arrowOpen]: isOpen }), children: _jsx(IconArrowDown, {}) })] })), children: _jsx("div", { className: s.collapsedContent, children: qa.answer }) }) }, qa.question)))] }))] }));
};

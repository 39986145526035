import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from 'classnames';
import { upperFirst } from 'lodash-es';
import { IconClock, IconCrossOutline, IconTickOutline } from '@copper/ui-kit';
import { getAddressStatus } from '@copper/components/AddressBook/Popups/AddressDetails/helpers';
import s from './VerificationStatus.module.css';
export const VerificationStatus = ({ address, defaultStatus, size = 'm', view = 'default' }) => {
    const status = defaultStatus || getAddressStatus(address);
    if (!status || status === 'not-required') {
        return null;
    }
    const iconSize = size === 's' ? 16 : 24;
    const getStatus = () => {
        switch (status) {
            case 'processing':
            case 'action-required': {
                return 'Processing';
            }
            case 'unverified': {
                return 'Not verified';
            }
            default: {
                return upperFirst(status);
            }
        }
    };
    const getIcon = () => {
        if (status === 'verified') {
            return _jsx(IconTickOutline, { width: iconSize, height: iconSize });
        }
        if (status === 'processing' || status === 'action-required') {
            return _jsx(IconClock, { width: iconSize, height: iconSize });
        }
        return _jsx(IconCrossOutline, { width: iconSize, height: iconSize });
    };
    return (_jsxs("div", { className: cn(s.container, s[`view_${view}`], {
            [s.container_unverified]: status !== 'verified',
            [s.container_pending]: status === 'processing' || status === 'action-required'
        }), children: [getIcon(), _jsx("p", { className: s[`size_${size}`], children: getStatus() })] }));
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cuid from 'cuid';
import { getUnixTime } from 'date-fns';
import useStateMachine from '@cassiozen/usestatemachine';
import { createOrder } from '@copper/entities/orders/orders-reducer';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { selectStakingCurrencies } from '@copper/entities/stake/stake-selector';
import { canStakeDelegation, canStakeNomination, hasEndTime, hasPendingPoolCalculation } from '@copper/helpers/stake';
import { useFetchStakePool } from '@copper/hooks/useFetchStakePools';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { StakeForm } from './Form';
import { StakingGuide } from './Guide';
import { Initial } from './Initial';
import { Result } from './Result';
import { Summary } from './Summary';
import { getBlockchainTransactionType, validationSchema } from './helpers';
export const Stake = () => {
    const dispatch = useDispatch();
    const { setTopPanel } = useToolbar();
    const { route } = useRoutingContext();
    const portfolios = useSelector(selectPortfolios);
    const stakingCurrencies = useSelector(selectStakingCurrencies);
    const [transaction, setTransaction] = useState();
    const [transactionErrorMessage, setTransactionErrorMessage] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const form = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            rewardAddress: ''
        }
    });
    const { setValue, watch } = form;
    const { currency, portfolio, poolOperation } = watch();
    const currencyMayHavePool = (currency?.currency !== 'ETH' &&
        canStakeDelegation(currency) &&
        !canStakeNomination(currency)) ||
        currency?.currency === 'SOL';
    const { predefinedPool, isFetchingPool } = useFetchStakePool(currency?.currency, currency?.mainCurrency, portfolio?.portfolioId, currencyMayHavePool, hasPendingPoolCalculation(currency));
    const [state, send] = useStateMachine({
        initial: 'initial',
        states: {
            initial: {
                on: { NEXT_STEP: 'form', RESET_FORM: 'form', SHOW_GUIDE: 'guide' }
            },
            form: {
                on: {
                    BACK_STEP: 'initial',
                    NEXT_STEP: 'summary',
                    SHOW_GUIDE: 'guide'
                }
            },
            summary: {
                on: { BACK_STEP: 'form', NEXT_STEP: 'result', RESET_FORM: 'form', SHOW_GUIDE: 'guide' }
            },
            result: { on: { RESET: 'initial', SHOW_GUIDE: 'guide' } },
            guide: { on: { BACK_STEP: 'initial' } }
        }
    });
    useEffect(() => {
        if (currencyMayHavePool) {
            if (predefinedPool) {
                setValue('poolOperation', 'selectPredefined');
            }
            else {
                setValue('poolOperation', 'connect');
            }
        }
    }, [currency, predefinedPool, setValue]);
    useEffect(() => {
        setValue('predefinedPool', predefinedPool);
    }, [predefinedPool, setValue]);
    useEffect(() => {
        setTopPanel({
            title: state.value === 'guide' ? 'Staking guide' : 'Stake',
            showGuideText: state.value === 'initial' ? 'Staking guide' : undefined,
            onBack: state.value === 'form' || state.value === 'summary'
                ? () => send({ type: 'BACK_STEP' })
                : undefined,
            showGuide: state.value === 'initial'
                ? () => {
                    send({ type: 'SHOW_GUIDE' });
                }
                : undefined,
            onCloseClickOverride: state.value === 'guide' ? () => send({ type: 'BACK_STEP' }) : undefined
        });
    }, [state, send, setTopPanel]);
    useEffect(() => {
        if (state.value === 'initial') {
            form.reset();
        }
    }, [state.value]);
    useEffect(() => {
        if (route?.name === 'stake') {
            route.data?.currencyId && send('RESET_FORM');
            const data = route?.data;
            const defaultValues = {
                currency: stakingCurrencies.find((item) => item.currency === data?.currencyId) ??
                    form.getValues('currency'),
                portfolio: portfolios[data?.portfolioId ?? '']
            };
            form.reset(defaultValues);
        }
    }, [route]);
    const handleItemClick = (currency) => {
        form.setValue('currency', currency);
        send('NEXT_STEP');
    };
    const handleFormSubmit = () => {
        send('NEXT_STEP');
    };
    const handleFormSend = async () => {
        const { portfolio, currency, amount, poolId, remoteAccountPublicKey, predefinedPool, chains, serviceUrl, period, rewardAddress, endTime, rewardCycle, signerSignature, signerKey, authId } = form.getValues();
        if (!portfolio || !currency) {
            return;
        }
        const data = {
            externalOrderId: cuid(),
            orderType: 'withdraw',
            portfolioId: portfolio.portfolioId,
            baseCurrency: currency.currency,
            mainCurrency: currency.mainCurrency,
            amount: amount ?? '0',
            poolId: poolOperation === 'selectPredefined' ? predefinedPool?.poolId : poolId?.trim(),
            blockchainTransactionType: getBlockchainTransactionType(currency, poolOperation),
            remoteAccountPublicKey: remoteAccountPublicKey?.trim(), // XEM
            chains: chains?.split(',').map((chain) => chain.trim()),
            serviceUrl: serviceUrl?.trim(),
            period, // STX
            rewardAddress: rewardAddress?.trim(), // STX, BTC
            ...(hasEndTime(currency) &&
                endTime && {
                endTime: getUnixTime(endTime).toString()
            }),
            ...(currency.currency === 'STX' && {
                rewardCycle,
                signerSignature,
                signerKey,
                authId,
                rewardCurrency: 'BTC',
                rewardMainCurrency: 'BTC'
            }),
            ...(currency.currency === 'BTC' && {
                rewardCurrency: 'CORE',
                rewardMainCurrency: 'CORE'
            })
        };
        setIsSubmitting(true);
        setTransactionErrorMessage(undefined);
        try {
            const createdOrder = await dispatch(createOrder(data)).unwrap();
            setTransaction(createdOrder);
        }
        catch (err) {
            setTransactionErrorMessage(getErrorData(err).message);
        }
        finally {
            setIsSubmitting(false);
            send({ type: 'NEXT_STEP' });
        }
    };
    const handleDone = () => {
        form.reset();
        send('RESET');
    };
    return (_jsxs(_Fragment, { children: [state.value === 'initial' && _jsx(Initial, { onItemClick: handleItemClick }), state.value === 'form' && (_jsx(StakeForm, { form: form, onSubmit: handleFormSubmit, isFetchingPool: isFetchingPool })), state.value === 'summary' && (_jsx(Summary, { isSubmitting: isSubmitting, data: { ...form.getValues() }, onNext: handleFormSend })), state.value === 'result' && (_jsx(Result, { onDone: handleDone, transaction: transaction, formData: { ...form.getValues() }, errorMessage: transactionErrorMessage })), state.value === 'guide' && _jsx(StakingGuide, {})] }));
};

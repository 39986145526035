export const BANK_ROWS_MAP = new Map([
    ['accountDescription', 'Account description'],
    ['currency', 'Currency'],
    ['accountName', 'Account name'],
    ['recipientAddress', 'Recipient address'],
    ['intermediaryBank', 'Intermediary bank'],
    ['usCorrespondentBank', 'US correspondent bank'],
    ['bankName', 'Bank name'],
    ['iban', 'IBAN'],
    ['accountNumber', 'Account number'],
    ['swiftCode', 'SWIFT'],
    ['aba', 'Routing/ABA'],
    ['bankAddress', 'Bank address'],
    ['sortCode', 'Sort code'],
    ['referenceNumber', 'Reference']
]);

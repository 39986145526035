import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cuid from 'cuid';
import { fromZonedTime } from 'date-fns-tz';
import useStateMachine from '@cassiozen/usestatemachine';
import { Form } from '@copper/client/components/Lend/AgencyLendingParentLoan/Form';
import { Success } from '@copper/client/components/Lend/AgencyLendingParentLoan/Success';
import { Summary } from '@copper/client/components/Lend/AgencyLendingParentLoan/Summary';
import { validationSchema } from '@copper/client/components/Lend/AgencyLendingParentLoan/helpers';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { createLoan } from '@copper/entities/lending/lending-reducer';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
export const AgencyLendingParentLoan = ({ onBack }) => {
    const dispatch = useDispatch();
    const [newLoan, setNewLoan] = useState(null);
    const { setTopPanel } = useToolbar();
    const { organizationId } = useSelector(selectCurrentOrganization);
    const { openSnackbar } = useSnackBar();
    const lendingForm = useForm({
        resolver: yupResolver(validationSchema)
    });
    const { handleSubmit, reset } = lendingForm;
    const [state, send] = useStateMachine({
        initial: 'lendingForm',
        states: {
            lendingForm: {
                on: {
                    NEXT: 'summary'
                }
            },
            summary: {
                on: { BACK: 'lendingForm', SUCCESS: 'success' }
            },
            success: {
                on: {
                    DONE: 'lendingForm',
                    REPEAT: 'lendingForm'
                }
            }
        }
    });
    useEffect(() => {
        setTopPanel({
            title: 'Lend',
            onBack: state.value === 'summary' ? () => send('BACK') : onBack
        });
    }, [setTopPanel, send, state]);
    const handleCreateLoan = handleSubmit(async (formValues) => {
        try {
            const { portfolio, amount, currency, mainCurrency, maturityDate, counterparty } = formValues;
            const newLoan = {
                loanType: 'agency-lending-parent-loan',
                externalId: cuid(),
                counterpartyId: counterparty.counterpartyId,
                portfolioId: portfolio.portfolioId,
                amount: amount,
                currency: currency.currency,
                mainCurrency: mainCurrency,
                ...(maturityDate && {
                    maturityDate: fromZonedTime(new Date(maturityDate), 'UTC').getTime().toString()
                })
            };
            const loanResponse = await await dispatch(createLoan({ organizationId, data: newLoan })).unwrap();
            setNewLoan(loanResponse);
            send('SUCCESS');
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    });
    const handleDone = () => {
        send('DONE');
        setNewLoan(null);
        reset();
    };
    return (_jsx("div", { children: _jsxs(FormProvider, { ...lendingForm, children: [state.value === 'lendingForm' && _jsx(Form, { onNext: handleSubmit(() => send('NEXT')) }), state.value === 'summary' && _jsx(Summary, { onSubmit: handleCreateLoan }), state.value === 'success' && newLoan && (_jsx(Success, { onDone: handleDone, loanId: newLoan.loanId, onRepeat: () => send('REPEAT') }))] }) }));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import cuid from 'cuid';
import { AddExchangeAddressForm } from '@copper/components';
import { AddressFormWrapper } from '@copper/components/DepositAddresses/AddressFormWrapper';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { createDepositTarget, fetchDepositTargets, updateDepositTarget } from '@copper/entities/depositTargets/depositTargets-actions';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { selectUser } from '@copper/entities/user/user-selector';
import { removeDepositAddress, updateDepositAddresses } from '@copper/entities/wallets/wallets-actions';
import { createWallet } from '@copper/entities/wallets/wallets-reducer';
import { hasAdditionalFields } from '@copper/helpers/currencies';
import { isExternalPortfolio, isTradingPortfolio, isVaultPortfolio } from '@copper/helpers/portfolios';
import { getWallet } from '@copper/helpers/wallets';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { AddAddressForm } from './AddAddressForm';
import { AddressItem } from './AddressItem';
import { GenerateAddress } from './GenerateAddress';
import { Header } from './Header';
import { SkeletonLoader } from './SkeletonLoader';
import { DEPOSIT_TARGETS_PER_PAGE, DESTINATION_TYPE_MAP, EXTERNAL_DEPOSIT_TARGETS_PER_PAGE, TARGET_TYPE_MAP } from './constants';
import { canTargetHaveMultipleAddresses } from './helpers';
import s from './DepositAddresses.module.css';
const DepositAddresses = ({ currency, mainCurrency, portfolioId, view = 'primary', targetType = 'direct', portfolios = {}, walledGardenManager, walletsByPortfolioId }) => {
    const { openSnackbar } = useSnackBar();
    const dispatch = useDispatch();
    const { checkPermissions } = useCheckPermissionsContext();
    const { isAdmin } = useSelector(selectUser);
    const { tokens } = useSelector(selectCurrencies);
    const [depositTargets, setDepositTargets] = useState([]);
    const [depositTargetsOffset, setTargetsOffset] = useState(0);
    const [moreItemsToPage, setMoreItemsToPage] = useState(false);
    const [isAddingAddress, setIsAddingAddress] = useState(false);
    const [isEditingAddress, setIsEditingAddress] = useState(false);
    const [isFetchingAddresses, setIsFetchingAddresses] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const hasDestinationTag = hasAdditionalFields('destinationTag', tokens, currency, mainCurrency);
    const hasDestinationMemo = hasAdditionalFields('memo', tokens, currency, mainCurrency);
    const destinationType = (hasDestinationTag && 'destinationTag') || (hasDestinationMemo && 'memo') || undefined;
    const needAddAddressIdentifier = depositTargets.length > 0 && !!destinationType;
    const { portfolioType } = portfolios[portfolioId] ?? {};
    const isClientCanAddExchangeAddress = !isAdmin && walledGardenManager === 'client';
    const isAdminCanAddExchangeAddress = isAdmin && walledGardenManager === 'admin';
    const wallet = (walletsByPortfolioId[portfolioId] ?? []).find((wallet) => wallet.currency === currency);
    const canAddExchangeAddress = isExternalPortfolio(portfolioType) &&
        (isClientCanAddExchangeAddress || isAdminCanAddExchangeAddress) &&
        checkPermissions(['update-external-wallet-deposit-address'], portfolioId) &&
        !depositTargets.length &&
        Boolean(wallet);
    const ITEMS_PER_PAGE = isExternalPortfolio(portfolioType)
        ? EXTERNAL_DEPOSIT_TARGETS_PER_PAGE
        : DEPOSIT_TARGETS_PER_PAGE;
    const showAddDepositButton = () => {
        if (isAdmin && !isExternalPortfolio(portfolioType)) {
            return false;
        }
        if (isVaultPortfolio(portfolioType) &&
            !getWallet(currency, mainCurrency, portfolioType, walletsByPortfolioId[portfolioId])) {
            return false;
        }
        return ((canTargetHaveMultipleAddresses(portfolioType, targetType) &&
            !isExternalPortfolio(portfolioType)) ||
            (canAddExchangeAddress && !needAddAddressIdentifier));
    };
    const createTradingWallet = async (mainCurrency) => {
        await dispatch(createWallet({
            currency,
            mainCurrency,
            portfolioId,
            walletId: cuid()
        })).unwrap();
        await getDepositTargets(depositTargetsOffset);
    };
    const addDepositTarget = async (mainCurrency, name, isNonCustodialWallet = false) => {
        try {
            setIsAddingAddress(true);
            const wallet = getWallet(currency, mainCurrency, portfolioType, walletsByPortfolioId[portfolioId]);
            // for trading portfolio we need to create wallet if it doesn't exist when we click 'Generate address' button
            // Deposit target will be created automatically during wallet creation if mainCurrency is specified.
            if (!wallet) {
                await createTradingWallet(mainCurrency);
            }
            else {
                await createDepositTarget({
                    currency,
                    externalId: cuid(),
                    mainCurrency,
                    name,
                    portfolioId,
                    isNonCustodialWallet
                });
                await getDepositTargets(depositTargetsOffset);
            }
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setIsAddingAddress(false);
            setIsVisible(false);
        }
    };
    const addExchangeDepositAddresses = async ({ depositAddresses }) => {
        try {
            setIsAddingAddress(true);
            const wallet = (walletsByPortfolioId[portfolioId] ?? []).find((wallet) => wallet.currency === currency);
            if (wallet && depositAddresses) {
                await updateDepositAddresses(wallet.walletId, { depositAddresses });
                await getDepositTargets(depositTargetsOffset);
                setIsVisible(false);
            }
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setIsAddingAddress(false);
        }
    };
    const getDepositTargets = async (offset = 0) => {
        try {
            setIsFetchingAddresses(true);
            const data = await fetchDepositTargets({
                limit: ITEMS_PER_PAGE + 1,
                offset,
                currency,
                mainCurrency,
                portfolioId,
                targetType,
                orderGroup: 'enabled-first',
                status: 'enabled,pending,approval-pending'
            });
            setDepositTargets(data.slice(0, ITEMS_PER_PAGE));
            setMoreItemsToPage(data.length > ITEMS_PER_PAGE);
        }
        catch (err) {
            openSnackbar(getErrorData(err));
            setDepositTargets([]);
        }
        finally {
            setIsFetchingAddresses(false);
        }
    };
    const handleRemoveDepositAddress = async () => {
        try {
            setIsFetchingAddresses(true);
            if (wallet) {
                await removeDepositAddress(wallet.walletId, { mainCurrency });
                await getDepositTargets(depositTargetsOffset);
                openSnackbar({ type: 'success', message: 'Successfully deleted' });
            }
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setIsFetchingAddresses(false);
        }
    };
    const handleUpdateDepositAddress = async (depositTargetId, name) => {
        try {
            setIsEditingAddress(true);
            await updateDepositTarget(depositTargetId, { name });
            await getDepositTargets(depositTargetsOffset);
            openSnackbar({ type: 'success', message: 'Successfully updated' });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setIsEditingAddress(false);
        }
    };
    const handleClickNext = async () => {
        const offset = depositTargetsOffset + ITEMS_PER_PAGE;
        await getDepositTargets(offset);
        setTargetsOffset(offset);
    };
    const handleClickPrev = async () => {
        const offset = depositTargetsOffset - ITEMS_PER_PAGE;
        await getDepositTargets(offset);
        setTargetsOffset(offset);
    };
    useEffect(() => {
        if (isVisible) {
            setIsVisible(false);
        }
        getDepositTargets();
    }, [currency, mainCurrency, portfolioId]);
    if (isFetchingAddresses) {
        return _jsx(SkeletonLoader, {});
    }
    if (!isAdmin && isTradingPortfolio(portfolioType) && depositTargets.length === 0) {
        return (_jsx(GenerateAddress, { currency: currency, mainCurrency: mainCurrency, isSubmitting: isAddingAddress, onSubmit: addDepositTarget }));
    }
    return (_jsxs("div", { className: cn(s.addressesContainer, s[`view_${view}`]), children: [_jsx(Header, { view: view, targetType: targetType, portfolioType: portfolioType, isFirstPage: depositTargetsOffset < ITEMS_PER_PAGE, hasMorePages: moreItemsToPage, onClickNext: handleClickNext, onClickPrev: handleClickPrev, onClickAddButton: () => setIsVisible(true), showAddButton: showAddDepositButton() }), isVisible && !isExternalPortfolio(portfolioType) && (_jsx(AddressFormWrapper, { view: view, title: needAddAddressIdentifier
                    ? `Add new ${DESTINATION_TYPE_MAP[destinationType]}`
                    : 'Add new address', children: _jsx(AddAddressForm, { currency: currency, mainCurrency: mainCurrency, isSubmitting: isAddingAddress, onCancel: () => setIsVisible(false), onSubmit: addDepositTarget, isAddMemo: needAddAddressIdentifier }) })), isVisible && isExternalPortfolio(portfolioType) && (_jsx(AddressFormWrapper, { title: "Add new address", children: _jsx(AddExchangeAddressForm, { view: view, currency: currency, mainCurrencyId: mainCurrency, isSubmitting: isAddingAddress, onCancel: () => setIsVisible(false), onSubmit: addExchangeDepositAddresses }) })), depositTargets.length > 0 ? (_jsx("div", { className: s.listContainer, children: depositTargets.map((depositTarget) => (_jsx(AddressItem, { view: view, portfolioType: portfolioType, currency: currency, mainCurrency: mainCurrency, depositTarget: depositTarget, isSubmitting: isEditingAddress, showMoreActions: true, onRemoveDepositAddress: handleRemoveDepositAddress, onUpdateDepositAddress: handleUpdateDepositAddress, onActivateToken: () => {
                        getDepositTargets(depositTargetsOffset);
                    } }, depositTarget.depositTargetId))) })) : (_jsx("div", { className: s.noAddresses, children: TARGET_TYPE_MAP[targetType].noAddresses }))] }));
};
export { DepositAddresses };

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Breadcrumb } from '@copper/components/Breadcrumbs/Breadcrumb';
import { selectApiKeysForCurrentOrg } from '@copper/entities/apiKeys/apiKeys-selector';
import { capitalizeText } from '@copper/helpers/capitalize-text';
export const ApiKeysBreadcrumb = ({ keyValue = '', section = '', pathname }) => {
    const apiKeys = useSelector(selectApiKeysForCurrentOrg);
    const selectedKey = apiKeys.find((key) => key.value === keyValue);
    const base = pathname.split(section);
    return (_jsxs(_Fragment, { children: [_jsx(Breadcrumb, { pathname: base[0], children: "API keys" }), _jsx(Breadcrumb, { pathname: base[0] + section, children: capitalizeText(section) }), _jsx(Breadcrumb, { pathname: pathname, children: selectedKey?.extra?.name ?? '-' })] }));
};

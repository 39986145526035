import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import useStateMachine from '@cassiozen/usestatemachine';
import { AlgoGovernance } from '@copper/client/components/Generate/AlgoGovernance';
import { GenerateAlgo } from '@copper/client/components/Generate/GenerateAlgo';
import { Initial } from '@copper/client/components/Generate/Initial';
import { ValidatorNode } from '@copper/client/components/Generate/ValidatorNode';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
export const Generate = () => {
    const { setTopPanel } = useToolbar();
    const { route } = useRoutingContext();
    const [state, send] = useStateMachine({
        initial: 'initial',
        states: {
            initial: {
                on: {
                    GENERATE_ALGO: 'generateAlgo',
                    GENERATE_VALIDATOR: 'generateValidator',
                    ALGO_GOVERNANCE: 'algoGovernance'
                }
            },
            generateAlgo: {
                on: {
                    BACK: 'initial'
                }
            },
            generateValidator: {
                on: {
                    BACK: 'initial'
                }
            },
            algoGovernance: {
                on: {
                    BACK: 'initial'
                }
            }
        }
    });
    useEffect(() => {
        if (route?.name === 'generate') {
            route?.state === 'generateAlgo' && send('GENERATE_ALGO');
            route?.state === 'generateValidator' && send('GENERATE_VALIDATOR');
            route?.state === 'algoGovernance' && send('ALGO_GOVERNANCE');
        }
    }, [route]);
    useEffect(() => {
        setTopPanel({
            title: state.value === 'initial' ? 'Generate transactions' : 'Generate',
            onBack: state.value !== 'initial' ? () => send('BACK') : undefined
        });
    }, [state, setTopPanel]);
    return (_jsxs("div", { children: [state.value === 'initial' && _jsx(Initial, { send: send }), state.value === 'generateAlgo' && (_jsx(GenerateAlgo, { onBack: () => send('BACK'), onDone: () => send('BACK') })), state.value === 'generateValidator' && (_jsx(ValidatorNode, { onDone: () => send('BACK'), onBack: () => send('BACK') })), state.value === 'algoGovernance' && (_jsx(AlgoGovernance, { onDone: () => send('BACK'), onBack: () => send('BACK') }))] }));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import cuid from 'cuid';
import useStateMachine from '@cassiozen/usestatemachine';
import { AlgoVotingForm } from '@copper/client/components/Generate/GenerateAlgo/AlgoVotingForm';
import { Result } from '@copper/client/components/Generate/GenerateAlgo/Result';
import { Summary } from '@copper/client/components/Generate/GenerateAlgo/Summary';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { createOrder } from '@copper/entities/orders/orders-reducer';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { validationSchema } from './helpers';
import s from '../Generate.module.css';
export const GenerateAlgo = ({ onBack, onDone }) => {
    const [transaction, setTransaction] = useState();
    const dispatch = useDispatch();
    const { openSnackbar } = useSnackBar();
    const { setTopPanel } = useToolbar();
    const algoForm = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            blockchainTransactionType: 'register-online'
        }
    });
    const { reset, handleSubmit, getValues } = algoForm;
    const [state, send] = useStateMachine({
        initial: 'algoVotingForm',
        states: {
            algoVotingForm: {
                on: {
                    SUMMARY: 'summary'
                }
            },
            summary: {
                on: {
                    BACK: 'algoVotingForm',
                    RESULT: 'result'
                }
            },
            result: {}
        }
    });
    useEffect(() => {
        setTopPanel({
            title: 'Generate',
            onBack: state.value === 'result'
                ? undefined
                : () => {
                    if (state.value === 'algoVotingForm')
                        return onBack();
                    return send('BACK');
                }
        });
    }, [state, setTopPanel]);
    const handleCreateOrder = handleSubmit(async () => {
        try {
            const formValues = getValues();
            let data = {
                portfolioId: formValues.portfolio.portfolioId,
                blockchainTransactionType: formValues.blockchainTransactionType,
                voteFirst: formValues.voteFirst,
                externalOrderId: cuid(),
                baseCurrency: 'ALGO',
                mainCurrency: 'ALGO',
                orderType: 'withdraw',
                amount: '0'
            };
            if (formValues.blockchainTransactionType === 'register-online') {
                data = {
                    ...data,
                    voteKey: formValues.voteKey,
                    selectionKey: formValues.selectionKey,
                    voteLast: formValues.voteLast,
                    voteKeyDilution: formValues.voteKeyDilution,
                    stateProofKey: formValues.stateProofKey
                };
            }
            const res = await dispatch(createOrder(data)).unwrap();
            setTransaction(res);
            send('RESULT');
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    });
    const handleDone = () => {
        setTransaction(undefined);
        reset();
        onDone();
    };
    return (_jsx("div", { className: s.mainWrapper, children: _jsxs(FormProvider, { ...algoForm, children: [state.value === 'algoVotingForm' && (_jsx(AlgoVotingForm, { onNext: handleSubmit(() => send('SUMMARY')) })), state.value === 'summary' && _jsx(Summary, { onSubmit: handleCreateOrder }), state.value === 'result' && transaction && (_jsx(Result, { onDone: handleDone, transaction: transaction }))] }) }));
};

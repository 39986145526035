import { fuzzySearch } from '@copper/ui-kit';
export const getOrganizationsToDisplay = (items, searchQuery, currentOrganization) => {
    const otherOrganization = items.filter((organization) => organization.organizationId !== currentOrganization.organizationId);
    if (searchQuery === '')
        return otherOrganization;
    return fuzzySearch({
        items: otherOrganization,
        keys: ['organizationId', 'organizationName'],
        searchQuery
    });
};

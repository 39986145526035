import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { AssetIcon, InfoRow } from '@copper/components';
import { selectCurrenciesEntities } from '@copper/entities/currencies/currencies-selector';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { getCurrencyName, getCurrencyProtocol, getMainCurrencies, isMultiNetworkCurrencyOrToken } from '@copper/helpers';
import { Atom } from '@copper/ui-kit';
import s from './NetworkInfoRow.module.css';
export const NetworkInfoRow = ({ baseCurrency, mainCurrency, title = 'Network', titleColor = 'secondary' }) => {
    const { tokens } = useSelector(selectCurrencies);
    const currenciesEntities = useSelector(selectCurrenciesEntities);
    const mainCurrencies = getMainCurrencies(currenciesEntities, baseCurrency);
    const currencyProtocol = getCurrencyProtocol(baseCurrency, mainCurrency || '', tokens);
    if (!isMultiNetworkCurrencyOrToken(baseCurrency, mainCurrency, mainCurrencies)) {
        return null;
    }
    return (_jsx(InfoRow, { title: title, titleColor: titleColor, children: _jsx(Atom, { align: "right", title: mainCurrency, subtext: _jsxs("span", { className: cn(s[`subtext_${titleColor}`]), children: [getCurrencyName(mainCurrency || '', currenciesEntities), ' ', currencyProtocol && `(${currencyProtocol?.toUpperCase()})`] }), icon: _jsx(AssetIcon, { currency: mainCurrency, size: "s" }) }) }));
};

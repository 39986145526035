import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as reportsApi from '@copper/api/reports';
import { getFile } from '@copper/api/users';
import { asyncPoll } from '@copper/utils/asyncPoll';
import { normalizeReports } from './reports-normalize';
export const createReport = createAsyncThunk('reports/createReport', async (report, thunkAPI) => {
    try {
        await thunkAPI.dispatch(createNewReport(report));
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const getReportById = createAsyncThunk('reports/getReportById', async (reportId, thunkAPI) => {
    try {
        const response = await reportsApi.getReportById(reportId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const downloadInvoiceReport = createAsyncThunk('reports/downloadInvoiceReport', async ({ invoiceId, userId }, thunkApi) => {
    try {
        const { data: { fileId } } = await reportsApi.createInvoiceReport(invoiceId);
        const fileData = await asyncPoll(() => getFile(userId, fileId, { responseType: 'blob' }), 1000, 1800000);
        return {
            fileId,
            fileData
        };
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const reportsSlice = createSlice({
    name: 'reports',
    initialState: {
        entities: {}
    },
    reducers: {
        setReports(state, { payload }) {
            const { entities } = normalizeReports(payload);
            state.entities = entities;
        },
        updateReports(state, { payload }) {
            const { entities } = normalizeReports(payload);
            state.entities = { ...state.entities, ...entities };
        },
        createNewReport(state, action) {
            const { fileId } = action.payload;
            state.entities = {
                ...state.entities,
                [fileId]: {
                    ...action.payload
                }
            };
        }
    }
});
const { reducer, actions } = reportsSlice;
export const { setReports, updateReports, createNewReport } = actions;
export const reports = reducer;

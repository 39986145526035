import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CryptoForm } from '@copper/components/DepositCryptoForm';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { isCurrencyFiat } from '@copper/helpers';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
import { Tabs } from '@copper/ui-kit';
import { FiatForm } from './FiatForm';
import s from './Deposit.module.css';
export const Deposit = () => {
    const { route } = useRoutingContext();
    const { setTopPanel } = useToolbar();
    const { entities: currenciesEntities } = useSelector(selectCurrencies);
    const [activeTab, setActiveTab] = useState('crypto');
    useEffect(() => {
        if (route?.name === 'deposit') {
            return setActiveTab(isCurrencyFiat(currenciesEntities, route?.data?.currencyId) ? 'fiat' : 'crypto');
        }
    }, [route]);
    useEffect(() => {
        setTopPanel({ title: 'Deposit' });
    }, [setTopPanel]);
    return (_jsxs("div", { className: s.container, children: [_jsx(Tabs, { view: "green", items: [
                    {
                        title: 'Crypto',
                        value: 'crypto'
                    },
                    {
                        title: 'Fiat',
                        value: 'fiat'
                    }
                ], value: activeTab, onChange: (item) => setActiveTab(item.value) }), _jsx("div", { className: s.content, children: _jsxs("div", { className: s.formContainer, children: [activeTab === 'crypto' && (_jsx(CryptoForm, { view: "tool", ...(route?.name === 'deposit' && route?.data) })), activeTab === 'fiat' && _jsx(FiatForm, {})] }) })] }));
};

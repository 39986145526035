import { request } from '@copper/utils';
export const checkCredentials = ({ credentialsHash, target }) => request.post('/tokens', { target }, { headers: { Authorization: `Basic ${credentialsHash}` } });
export const createBearerToken = (data, config) => request.post('/tokens', data, config);
export const refreshBearerToken = (target) => request.post('/tokens', { target }, {
    headers: {
        'Content-Type': 'application/vnd.refresh-token+json'
    }
});
export const updateBearerToken = (tokenId, data, config) => request.patch(`/tokens/${tokenId}`, data, config);
export const getAuthSchemes = ({ email, target }) => request.get(`/authentication-schemes?target=${target}`, {
    headers: {
        'X-Auth-Email': email
    }
});
export const createPasskeyChallenge = (data, config) => request.post('/authentication-schemes/passkey/challenges', data, config);
export const logout = (token) => request.delete(`/tokens/${token}`);
export const logoutFromOtherSessions = (token) => request.delete(`/tokens?exceptTokenId=${token}`);
export const killSession = (token) => request.delete(`/tokens/${token}`);
export const getSessions = () => request.get('/tokens');
export const createSSOConfiguration = (data) => request.post('/sso/configurations', data);
export const getSSOConfigurationsByOrgId = (organizationId) => request.get(`/sso/configurations?organizationId=${organizationId}`);
export const updateSSOConfiguration = (configurationId, data) => request.patch(`/sso/configurations/${configurationId}`, data, {
    headers: {
        'Content-type': 'application/vnd.update-configuration+json'
    }
});

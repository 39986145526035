export class TypedBroadcastChannel extends BroadcastChannel {
    constructor() {
        super(...arguments);
        this.onmessage = null;
    }
    postMessage(message) {
        super.postMessage(message);
    }
    addEventListener(type, listener, options) {
        return super.addEventListener(type, listener, options);
    }
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as authApi from '@copper/api/auth';
import { updateOrganizationAuthFlag } from '@copper/entities/organizations/organizations-reducer';
import { normalizeUser } from '@copper/entities/user/user-normalize';
import { setUser } from '@copper/entities/user/user-reducer';
import { sendSyncLogout } from '@copper/hooks';
import { removeKeyValue, saveAuthData, setKeyValue } from '@copper/utils/localstorage';
import { clearAuthData, setAuthorizationHeader } from '@copper/utils/request';
const initialState = {
    token: '',
    expiredAt: '',
    credentialsHash: undefined,
    twoFaTypes: ['totp'],
    email: '',
    schemes: []
};
export const getAuthorizationHeader = ({ credential, ssoSessionToken, credentialsHash }) => {
    if (credential) {
        return 'passkey';
    }
    if (ssoSessionToken) {
        return `Sso ${ssoSessionToken}`;
    }
    if (credentialsHash) {
        return `Basic ${credentialsHash}`;
    }
    throw new Error('Authorization header param unspecified');
};
export const checkIsAuthorized = (auth) => Boolean(auth?.token && auth?.expiredAt && Number.parseInt(auth?.expiredAt, 10) > Date.now());
export const logout = createAsyncThunk('auth/logout', async (auth, thunkAPI) => {
    const { token } = auth;
    try {
        if (checkIsAuthorized(auth)) {
            await authApi.logout(token);
            setAuthorizationHeader(null);
            clearAuthData();
        }
        else {
            setAuthorizationHeader(null);
            clearAuthData();
        }
        removeKeyValue('reLoginData');
        sendSyncLogout();
        return initialState;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const checkCredentials = createAsyncThunk('auth/checkCredentials', async (data, thunkAPI) => {
    try {
        const response = await authApi.checkCredentials(data);
        return {
            credentialsHash: data.credentialsHash,
            twoFaTypes: response.data.twoFaTypes
        };
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const getAuthSchemes = createAsyncThunk('auth/getSchemes', async (data, thunkAPI) => {
    try {
        const response = await authApi.getAuthSchemes(data);
        saveAuthData({ email: data.email });
        return {
            email: data.email,
            schemes: response.data.authSchemes
        };
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createBearerToken = createAsyncThunk('auth/createBearerToken', async ({ target, ssoSessionToken, credentialsHash, twoFaCode, credential }, thunkAPI) => {
    const authorizationHeader = getAuthorizationHeader({
        ssoSessionToken,
        credentialsHash,
        credential
    });
    try {
        const { data: { token, expiredAt, _embedded } } = await authApi.createBearerToken({ target, credential }, {
            headers: {
                Authorization: authorizationHeader,
                ...(twoFaCode ? { 'X-2FA-Code': twoFaCode } : {}),
                ...(credential
                    ? { 'Content-Type': 'application/vnd.finish-passkey-challenge+json' }
                    : {})
            }
        });
        setAuthorizationHeader(token);
        saveAuthData({
            token,
            expiredAt,
            twoFaTypes: _embedded?.twoFaTypes,
            target
        });
        setKeyValue('user', normalizeUser(_embedded.user));
        thunkAPI.dispatch(setUser(_embedded.user));
        return {
            token,
            expiredAt,
            twoFaTypes: _embedded?.twoFaTypes,
            target
        };
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateBearerToken = createAsyncThunk('auth/extendBearerToken', async ({ organizationId, ssoSessionToken, credentialsHash, credential, token, target, configurationId }, thunkAPI) => {
    const authorizationHeader = getAuthorizationHeader({
        ssoSessionToken,
        credentialsHash,
        credential
    });
    try {
        const { data: { sessionId } } = await authApi.updateBearerToken(token, { credential, target, configurationId }, {
            headers: {
                'Content-Type': 'application/vnd.promote-authorization-token+json',
                'X-Promote-Authorization': authorizationHeader
            }
        });
        await thunkAPI.dispatch(updateOrganizationAuthFlag(organizationId));
        return sessionId;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const refreshBearerToken = createAsyncThunk('auth/refreshToken', async (target, thunkAPI) => {
    try {
        const { data } = await authApi.refreshBearerToken(target);
        // if more than one refresh token req were made with the same current token, api return status 204 and empty resp.
        // It can happen when two or more tabs trying to refresh token at exact same time
        // When data is empty, we should do nothing, everything will be synced from first resp via broadcast channel
        if (!data) {
            return;
        }
        setAuthorizationHeader(data.token);
        saveAuthData({
            token: data.token,
            expiredAt: data.expiredAt
        });
        return data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateToken(state, { payload }) {
            state.token = payload.token;
            state.expiredAt = payload.expiredAt;
        },
        updateTwoFaType(state, { payload }) {
            if (payload.twoFaTypes) {
                state.twoFaTypes = payload.twoFaTypes;
            }
        },
        resetAuth() {
            return initialState;
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(checkCredentials.fulfilled, (state, { payload }) => {
            state.credentialsHash = payload.credentialsHash;
            state.twoFaTypes = payload.twoFaTypes;
        });
        addCase(createBearerToken.fulfilled, (state, { payload }) => {
            state.token = payload.token;
            state.expiredAt = payload.expiredAt;
            state.twoFaTypes = payload.twoFaTypes;
            state.target = payload.target;
        });
        addCase(refreshBearerToken.fulfilled, (state, { payload }) => {
            if (payload) {
                state.token = payload.token;
                state.expiredAt = payload.expiredAt;
            }
        });
        addCase(logout.fulfilled, (state, { payload }) => {
            state.token = payload.token;
            state.expiredAt = payload.expiredAt;
            state.credentialsHash = payload.credentialsHash;
        });
        addCase(getAuthSchemes.fulfilled, (state, { payload }) => {
            state.email = payload.email;
            state.schemes = payload.schemes;
        });
    }
});
const { actions } = authSlice;
export const { updateToken, resetAuth, updateTwoFaType } = actions;
export const auth = authSlice.reducer;

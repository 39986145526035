import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { BankAccount } from '@copper/client/components/BankAccount';
import { AssetIcon } from '@copper/components';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { oxygenMainNetwork } from '@copper/constants/currency';
import { selectDepositableByBankAccount } from '@copper/entities/currencies/currencies-selector';
import { getDepositBankAccounts } from '@copper/entities/dictionaries/dictionaries-actions';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { sendFiatDepositNotification } from '@copper/entities/organizations/organizations-actions';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { selectGroupedPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { useRoutingContext } from '@copper/hooks';
import { Button, FormControl, FormLabel, NumberInput, Select, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { validationSchema } from './helpers';
import s from './OxygenDeposit.module.css';
export const OxygenDeposit = () => {
    const { setTopPanel } = useToolbar();
    const { route } = useRoutingContext();
    const { openSnackbar } = useSnackBar();
    const { tokens } = useSelector(selectCurrencies);
    const portfolios = useSelector(selectGroupedPortfolios);
    const currentOrganization = useSelector(selectCurrentOrganization);
    const depositableCurrencies = useSelector(selectDepositableByBankAccount);
    const [bankAccounts, setBankAccounts] = useState([]);
    const { control, formState: { errors, isSubmitting, isSubmitSuccessful }, watch, reset, handleSubmit } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const { currency } = watch();
    const account = portfolios.vaultPortfolios[0];
    const bankAccount = bankAccounts?.[0];
    const fromBankAccounts = useMemo(() => (currentOrganization?.bankAccounts ?? []).filter((bankAccount) => bankAccount?.currency === currency?.currency), [currentOrganization, currency]);
    const fetchDepositBankAccounts = useCallback(async () => {
        const tokenizedFromCurrency = tokens[`${currency?.currency}-${oxygenMainNetwork}`]?.extra?.tokenizedFrom;
        try {
            if (tokenizedFromCurrency) {
                const accounts = await getDepositBankAccounts({
                    currency: tokenizedFromCurrency,
                    organizationId: currentOrganization.organizationId
                });
                setBankAccounts(accounts);
            }
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    }, [currentOrganization, currency, tokens]);
    useEffect(() => {
        if (route?.name === 'oxygen-deposit') {
            reset({
                currency: depositableCurrencies.find((item) => item.currency === route?.data?.currencyId)
            });
            setBankAccounts([]);
        }
    }, [route]);
    useEffect(() => {
        setTopPanel({ title: 'Deposit' });
    }, [setTopPanel]);
    useEffect(() => {
        fetchDepositBankAccounts();
    }, [fetchDepositBankAccounts]);
    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
            setBankAccounts([]);
        }
    }, [isSubmitSuccessful, reset]);
    const handleNotificationSend = async (values) => {
        const { currency, amount } = values;
        try {
            await sendFiatDepositNotification(currentOrganization.organizationId, {
                organizationId: currentOrganization.organizationId,
                amount: amount ?? '',
                currency: currency?.currency ?? '',
                portfolioId: account?.portfolioId ?? '',
                depositBankAccountName: bankAccount?.accountName ?? '',
                fromClientBankAccountName: fromBankAccounts[0]?.bankAccountName ?? ''
            });
            openSnackbar({
                type: 'success',
                message: 'Notification sent. Thank you!'
            });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    };
    return (_jsx("div", { className: s.container, children: _jsx("div", { className: s.content, children: _jsxs("form", { className: s.formContainer, onSubmit: handleSubmit(handleNotificationSend), children: [_jsxs(FormControl, { errorMessage: errors?.currency?.currency?.message, indent: "l", children: [_jsx(FormLabel, { view: "tool", children: "Asset" }), _jsx(Controller, { control: control, name: "currency", render: ({ field }) => (_jsx(Select, { view: "tool", placeholder: "Select asset", searchPlaceholder: "Search assets", items: depositableCurrencies, searchFields: ['name', 'currency'], getKey: (asset) => asset?.currency, getIcon: (asset) => _jsx(AssetIcon, { currency: asset?.currency, size: "s" }), getTopRow: (asset) => asset?.name, getBottomRow: (asset) => asset?.currency, emptySearchString: "No assets match this name", onChange: field.onChange, selected: field.value, reversedStyles: true })) })] }), bankAccount && (_jsx(BankAccount, { bankAccount: bankAccount, excludedFields: ['accountDescription', 'currency'] })), _jsxs("div", { className: s.notificationContainer, children: [_jsx("h6", { className: s.notificationTitle, children: "Notify us about upcoming deposits" }), "To ensure your deposit is successful, let us know how much to expect ahead of time."] }), _jsxs(FormControl, { errorMessage: errors?.amount?.message, children: [_jsx(FormLabel, { view: "tool", children: "Deposit amount" }), _jsx(Controller, { control: control, name: "amount", render: ({ field }) => (_jsx(NumberInput, { size: "l", view: "tool", value: field.value ?? '', onValueChange: ({ value }) => field.onChange(value), decimalScale: 8, rightSection: currency?.currency ?? '' })) })] }), _jsx("div", { className: s.buttonContainer, children: _jsx(Button, { loading: isSubmitting, text: "Send notification", type: "submit" }) })] }) }) }));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { formatInTimeZone } from 'date-fns-tz';
import { InfoList, InfoRow } from '@copper/components';
import { useDateTime } from '@copper/hooks';
import s from './InvoiceExtraDetails.module.css';
const InvoiceExtraDetails = ({ invoice }) => {
    const { getFormattedDateAndTime } = useDateTime();
    const { dueDate, invoiceReferenceNumber, organizationId, period, status, updatedAt } = invoice;
    return (_jsxs(InfoList, { className: s.list, title: "Other information", children: [_jsx(InfoRow, { title: "Organisation ID", children: organizationId }), _jsx(InfoRow, { title: "Invoice number", children: invoiceReferenceNumber }), _jsx(InfoRow, { title: "Invoice period", children: formatInTimeZone(parseInt(period), 'UTC', 'MMMM yyyy') }), dueDate && (_jsx(InfoRow, { title: "Invoice due", children: formatInTimeZone(parseInt(dueDate), 'UTC', 'dd MMMM yyyy') })), status === 'paid' && (_jsx(InfoRow, { title: "Paid", children: getFormattedDateAndTime(updatedAt ?? '') }))] }));
};
export { InvoiceExtraDetails };

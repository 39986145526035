import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import useStateMachine from '@cassiozen/usestatemachine';
import { ExchangeForm } from '@copper/components/AddAccountPopup/ExchangeForm';
import { ExchangeResult } from '@copper/components/AddAccountPopup/ExchangeResult';
import { Popup, PopupHeader } from '@copper/ui-kit';
import { PopupContent } from '@copper/ui-kit/Popup/PopupContent';
const AddChildPortfolioPopup = ({ onBack, parentPortfolio }) => {
    const [connectedPortfolio, setConnectedPortfolio] = useState();
    const [state, send] = useStateMachine({
        initial: 'addChildPortfolio',
        states: {
            addChildPortfolio: {
                on: {
                    RESULT: 'exchangeResult'
                }
            },
            exchangeResult: {}
        }
    });
    return (_jsxs(Popup, { onClose: onBack, children: [_jsx(PopupHeader, { title: "Connect exchange account", onBack: state.value === 'addChildPortfolio' ? onBack : undefined }), state.value === 'addChildPortfolio' && (_jsx(ExchangeForm, { onNext: (portfolio) => {
                    setConnectedPortfolio(portfolio);
                    send('RESULT');
                }, predefinedParentPortfolio: parentPortfolio })), state.value === 'exchangeResult' && (_jsx(PopupContent, { children: _jsx(ExchangeResult, { onSuccess: onBack, portfolioId: connectedPortfolio?.portfolioId }) }))] }));
};
export { AddChildPortfolioPopup };

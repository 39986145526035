import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import cn from 'classnames';
import { PortfolioIcon } from '@copper/components';
import { flattenNestedPortfolios } from '@copper/helpers/nestedPortfolios';
import { isClearloopPortfolio, isClearloopSubAccount, isTradingClearloopPortfolio } from '@copper/helpers/portfolios';
import { ButtonIcon, IconAlert, IconArrowDown, IconClearloop, IconGradientDefi, IconGradientOxygen, IconLoan, IconMain, IconSettlement, Tooltip } from '@copper/ui-kit';
import { formatFiat } from '@copper/utils';
import { getCssPropertyValue } from '@copper/utils/styles';
import s from './SidebarMenuItem.module.css';
const menuPadding = getCssPropertyValue('--menu-item-padding');
const arrowPadding = getCssPropertyValue('--menu-arrow-padding');
export const SidebarMenuItem = ({ item, forceShowChildren = false }) => {
    const routeParams = useParams();
    const { item: portfolio, next, nestingLevel } = item;
    const childPortfolios = next ? flattenNestedPortfolios(next) : [];
    const hasActiveChild = childPortfolios.some((item) => item.portfolioId === routeParams.portfolioId);
    const [openChild, setOpenChild] = useState(hasActiveChild);
    useEffect(() => {
        if (forceShowChildren) {
            setOpenChild(forceShowChildren);
        }
    }, [forceShowChildren]);
    const isActive = portfolio.portfolioId === routeParams.portfolioId;
    const Component = isActive || portfolio.isShadow ? 'div' : NavLink;
    const hasClearLoopChild = nestingLevel === 1 &&
        childPortfolios.some(({ portfolioType }) => isClearloopPortfolio(portfolioType));
    const autodiscoveredAccountStatus = portfolio?.extra?.autodiscoveredAccountStatus;
    const handleArrowClick = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        setOpenChild(!openChild);
    }, [openChild]);
    return (_jsxs(_Fragment, { children: [_jsxs(Component, { to: `accounts/${portfolio.portfolioId}${routeParams?.section ? `/${routeParams.section}` : ''}`, title: portfolio.isShadow ? 'Access denied' : '', style: { paddingLeft: menuPadding * nestingLevel + 'px' }, className: cn(s.item, {
                    [s.active]: isActive,
                    [s.disabled]: portfolio.isShadow
                }), children: [!!next?.length && (_jsx("div", { className: s.arrowButton, style: { left: menuPadding * (nestingLevel - 1) + arrowPadding + 'px' }, children: _jsx(ButtonIcon, { tooltipLabel: openChild ? 'Collapse' : 'Expand', onClick: handleArrowClick, children: _jsx("span", { className: cn(s.arrow, { [s.arrowOpen]: openChild }), children: _jsx(IconArrowDown, { width: "16px", height: "16px" }) }) }) })), _jsxs("div", { className: s.portfolioIcon, children: [_jsx(PortfolioIcon, { portfolio: portfolio }), Boolean(portfolio?.extra?.walledGardenFetchingError) && _jsx("span", { className: s.dot })] }), _jsxs("div", { className: s.nameWrapper, children: [_jsx("div", { className: s.name, children: portfolio.portfolioName }), _jsx("div", { className: s.balance, children: portfolio.isShadow ? '*****' : formatFiat(portfolio.balance, { short: true }) }), isClearloopPortfolio(portfolio.portfolioType) && (_jsxs("div", { className: s.accountType, children: [_jsx(IconSettlement, { width: 16, height: 16 }), " Settlement account"] })), isClearloopSubAccount(portfolio) && portfolio.extra?.mainClearLoop && (_jsxs("div", { className: s.accountType, children: [_jsx(IconMain, { width: 16, height: 16 }), " Main account"] }))] }), _jsxs("div", { className: s.iconWrapper, children: [(isTradingClearloopPortfolio(portfolio) || hasClearLoopChild) && (_jsx(Tooltip, { label: "ClearLoop account", children: _jsx(IconClearloop, { width: "18", height: "18" }) })), portfolio.portfolioType === 'oxygen-vault' && (_jsx(Tooltip, { label: "Oxygen account", children: _jsx(IconGradientOxygen, { width: 16, height: 16 }) })), portfolio.extra?.isDefiVault && (_jsx(Tooltip, { label: "DeFi account", children: _jsx(IconGradientDefi, { width: 16, height: 16 }) })), portfolio.extra?.isCollateralPortfolio && (_jsx(Tooltip, { label: "Collateral account", children: _jsx(IconLoan, { width: 16, height: 16, className: s.icon }) })), Boolean(autodiscoveredAccountStatus === 'frozen' ||
                                autodiscoveredAccountStatus === 'information-required') && (_jsx(Tooltip, { label: autodiscoveredAccountStatus === 'frozen'
                                    ? 'Account frozen'
                                    : 'Additional information needed', children: _jsx(IconAlert, { type: "error" }) }))] })] }), openChild && next && (_jsx(_Fragment, { children: next.map((item) => (_jsx(SidebarMenuItem, { item: item, forceShowChildren: forceShowChildren }, `child_${item.item.portfolioId}`))) }))] }));
};

import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash-es';
import { AVAILABLE_VERIFICATION_STATUSES } from '@copper/components/AddressBook/helpers';
import { DEFAULT_ITEMS_PER_PAGE_OFFSET, DEFAULT_ITEMS_PER_PAGE_VALUE } from '@copper/constants/pagination';
import { fetchCryptoAddresses } from '@copper/entities/addressBook/addressBook-actions';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
const useInfiniteAddresses = ({ addressFolderId, enabled = true, isNewAddresses = false, isArchived = false, defaultFilters = {} }) => {
    const currentOrganization = useSelector(selectCurrentOrganization);
    const [isLoadingAddresses, setIsLoadingAddresses] = useState(false);
    const [cryptoAddresses, setCryptoAddresses] = useState([]);
    const [offset, setOffset] = useState(DEFAULT_ITEMS_PER_PAGE_OFFSET);
    const [searchQuery, setSearchQuery] = useState('');
    const [canFetchMore, setCanFetchMore] = useState(true);
    const [filters, setFilters] = useState(defaultFilters);
    const { openSnackbar } = useSnackBar();
    const debouncedGetCryptoAddresses = useCallback(debounce(async ({ filters, searchQuery, offset, disabled }) => {
        if (disabled) {
            return;
        }
        try {
            setIsLoadingAddresses(true);
            const data = await fetchCryptoAddresses({
                limit: DEFAULT_ITEMS_PER_PAGE_VALUE.toString(),
                offset: offset.toString(),
                organizationId: currentOrganization?.organizationId || filters.organizationId,
                addressFolderId,
                search: searchQuery,
                isNewAddresses,
                currencies: filters.currencies,
                isCurrentlyArchived: isArchived,
                verificationStatuses: filters.verificationStatuses?.length
                    ? filters.verificationStatuses
                    : AVAILABLE_VERIFICATION_STATUSES,
                ...(filters.mainCurrencies && { mainCurrency: filters.mainCurrencies?.toString() }),
                ...(filters.portfolioIds && { portfolioIds: filters.portfolioIds }),
                ...(filters.addressTypes && {
                    addressType: filters.addressTypes.toString()
                }),
                ...(typeof filters.pendingApproval === 'boolean' && {
                    pendingApproval: filters.pendingApproval
                })
            });
            if (offset === 0) {
                setCryptoAddresses(data);
            }
            else {
                setCryptoAddresses((prev) => [...prev, ...data]);
            }
            const hasNextPage = data.length === DEFAULT_ITEMS_PER_PAGE_VALUE;
            setCanFetchMore(hasNextPage);
            if (hasNextPage) {
                setOffset((prev) => prev + DEFAULT_ITEMS_PER_PAGE_VALUE);
            }
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setIsLoadingAddresses(false);
        }
    }, 200), [currentOrganization, addressFolderId, isNewAddresses]);
    const debouncedSearch = useCallback(debounce((searchQuery) => {
        setOffset(DEFAULT_ITEMS_PER_PAGE_OFFSET);
        setCanFetchMore(true);
        setSearchQuery(searchQuery);
    }, 500), []);
    const handleSearchChange = (value) => {
        debouncedSearch(value);
    };
    const handleInfiniteScroll = (e) => {
        const bottom = e.currentTarget.scrollHeight - e.currentTarget.clientHeight === e.currentTarget.scrollTop;
        if (bottom) {
            debouncedGetCryptoAddresses({
                filters,
                searchQuery,
                disabled: isLoadingAddresses || !canFetchMore,
                offset
            });
        }
    };
    useEffect(() => {
        if ((currentOrganization?.organizationId || filters.organizationId) && enabled) {
            debouncedGetCryptoAddresses({
                filters,
                searchQuery,
                disabled: isLoadingAddresses || !canFetchMore,
                offset
            });
        }
    }, [currentOrganization, enabled, filters, searchQuery]);
    const handleSetFilters = (newFilters) => {
        setSearchQuery('');
        setOffset(0);
        setCanFetchMore(true);
        setFilters(newFilters);
    };
    return {
        isLoadingAddresses,
        cryptoAddresses,
        handleSearchChange,
        searchQuery,
        handleInfiniteScroll,
        setFilters: handleSetFilters
    };
};
export { useInfiniteAddresses };

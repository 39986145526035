import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cuid from 'cuid';
import useStateMachine from '@cassiozen/usestatemachine';
import { useDestination } from '@copper/client/components/TransferFunds/useDestination';
import { useTransferPortfolio } from '@copper/components/Forms/TransferPortfolio/helpers';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { selectActivePlatformCurrencies } from '@copper/entities/currencies/currencies-selector';
import { createOrder } from '@copper/entities/orders/orders-reducer';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
import { Button } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { sha256 } from '@noble/hashes/sha256';
import { bytesToHex } from '@noble/hashes/utils';
import { DEFAULT_FEE_LEVEL, validationSchema } from '../helpers';
import { SingleTransferForm } from './Form';
import { Result } from './Result';
import { Summary } from './Summary';
import { isAddressVerified } from '@copper/components/AddressBook/Popups/AddressDetails/helpers';
import s from './TransferSingle.module.css';
const stepMap = {
    form: 1,
    summary: 2,
    result: 3
};
const TransferSingle = () => {
    const dispatch = useDispatch();
    const { setTopPanel } = useToolbar();
    const { route } = useRoutingContext();
    const getSelectedPortfolio = useTransferPortfolio();
    const activeCurrencies = useSelector(selectActivePlatformCurrencies);
    const [transaction, setTransaction] = useState();
    const [transactionErrorMessage, setTransactionErrorMessage] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const form = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            feeLevel: DEFAULT_FEE_LEVEL
        }
    });
    const destinationByRoute = useDestination(route?.name === 'transfer'
        ? {
            toPortfolioId: route?.data?.toPortfolioId,
            toCounterpartyId: route?.data?.counterpartyId,
            toCryptoAddressId: route.data?.toCryptoAddressId,
            toBankAccountId: route.data?.toBankAccountId,
            toAddress: route.data?.toAddress
        }
        : undefined);
    useEffect(() => {
        if (route?.name === 'transfer') {
            send('RESET');
            const data = route?.data;
            const defaultValues = {
                feeLevel: DEFAULT_FEE_LEVEL,
                amount: data?.amount,
                currency: activeCurrencies.find((item) => item.currency === data?.currencyId),
                mainCurrency: data?.mainCurrencyId,
                origin: getSelectedPortfolio(data?.portfolioId),
                destination: destinationByRoute,
                includeFeeInWithdraw: data?.includeFeeInWithdraw,
                toAddress: data?.toAddress
            };
            form.reset(defaultValues);
        }
    }, [route]);
    const { getValues } = form;
    const [state, send] = useStateMachine({
        initial: 'form',
        states: {
            form: {
                on: { NEXT_STEP: 'summary' }
            },
            summary: {
                on: { NEXT_STEP: 'result', BACK_STEP: 'form', RESET: 'form' }
            },
            result: { on: { RESET: 'form', REPEAT: 'form' } }
        }
    });
    useEffect(() => {
        let subTitle = `Step ${stepMap[state.value]} of ${Object.keys(stepMap).length}`;
        if (state.value === 'result') {
            subTitle = transactionErrorMessage ? 'Failed' : 'Transaction created';
        }
        setTopPanel({
            title: 'Transfer funds',
            subTitle,
            onBack: state.value === 'summary' ? () => send({ type: 'BACK_STEP' }) : undefined
        });
    }, [state, send, setTopPanel, transactionErrorMessage]);
    const handleFormSubmit = () => {
        send({ type: 'NEXT_STEP' });
    };
    const handleFormSend = async (masterPassword) => {
        const formValues = getValues();
        const { destination } = formValues;
        const data = {
            externalOrderId: cuid(),
            orderType: 'withdraw',
            portfolioId: formValues?.origin?.portfolioId ?? '',
            baseCurrency: formValues.currency?.currency ?? '',
            mainCurrency: formValues.mainCurrency,
            amount: formValues.amount,
            description: formValues.description,
            feeLevel: formValues.feeLevel !== 'custom' ? formValues.feeLevel : undefined,
            includeFeeInWithdraw: formValues.includeFeeInWithdraw,
            requestedNetworkFees: formValues.requestedNetworkFees,
            toPortfolioId: destination && 'portfolioId' in destination ? destination.portfolioId : undefined,
            // destination.cryptoAddressId can be an empty string. It is used to detect 'New external address' field.
            toCryptoAddressId: destination && 'cryptoAddressId' in destination && destination.cryptoAddressId !== ''
                ? destination.cryptoAddressId
                : undefined,
            toNonCustodialWalletId: destination && 'nonCustodialWalletId' in destination
                ? destination.nonCustodialWalletId
                : undefined,
            toAddress: formValues.toAddress?.trim(),
            destinationTag: formValues.destinationTag?.trim(),
            memo: formValues.memo?.trim() || undefined,
            toCounterpartyId: destination && 'counterpartyId' in destination ? destination.counterpartyId : undefined,
            toBankAccountId: destination && 'bankAccountId' in destination ? destination.bankAccountId : undefined,
            nextTransferTo: formValues?.conditionalTransfers?.map((portfolio) => ({
                targetType: ('portfolioId' in portfolio ? 'portfolio' : 'external'),
                ...('portfolioId' in portfolio && { portfolioId: portfolio.portfolioId }),
                ...('cryptoAddressId' in portfolio && { cryptoAddressId: portfolio.cryptoAddressId })
            })),
            masterPassword: masterPassword ? bytesToHex(sha256(masterPassword)) : undefined
        };
        setIsSubmitting(true);
        setTransactionErrorMessage(undefined);
        try {
            const createdOrder = await dispatch(createOrder(data)).unwrap();
            setTransaction(createdOrder);
        }
        catch (err) {
            setTransactionErrorMessage(getErrorData(err).message);
        }
        finally {
            setIsSubmitting(false);
            send({ type: 'NEXT_STEP' });
        }
    };
    const handleDone = () => {
        form.reset();
        send('RESET');
    };
    const { conditionalTransfers, destination, origin } = form.watch();
    const unverifiedConditionalTransfer = conditionalTransfers?.find((item) => 'cryptoAddressId' in item && !isAddressVerified(item));
    const unverifiedDestination = destination && 'cryptoAddressId' in destination && !isAddressVerified(destination)
        ? destination
        : undefined;
    const isExternalOriginAddress = origin?.portfolioType === 'external';
    const unverifiedAddress = unverifiedDestination || unverifiedConditionalTransfer;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.container, children: [state.value === 'form' && (_jsx(SingleTransferForm, { form: form, onSubmit: handleFormSubmit, unverifiedAddress: unverifiedAddress, skipVerificationCheck: isExternalOriginAddress })), state.value === 'summary' && (_jsx(Summary, { isSubmitting: isSubmitting, data: { ...getValues() }, onNext: handleFormSend })), state.value === 'result' && (_jsx(Result, { onDone: handleDone, transaction: transaction, formData: { ...getValues() }, errorMessage: transactionErrorMessage }))] }), state.value === 'result' && (_jsx(Button, { text: "Repeat transaction", view: "frame", onClick: () => {
                    send('REPEAT');
                }, testId: "button-repeat-withdraw-transaction" }))] }));
};
export { TransferSingle };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStateMachine from '@cassiozen/usestatemachine';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { difference, intersection, pick } from 'lodash-es';
import { BulkTableFrame, FileDropZone, UploadFailure, useBulkOrder } from '@copper/client/components/BulkOrderTools';
import { reportingCurrency } from '@copper/constants/currency';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { bulkOrderCreate, downloadBulkTemplate, uploadBulkTemplate } from '@copper/entities/settlements/actions';
import { selectUserId } from '@copper/entities/user/user-selector';
import { saveBlobAsFile } from '@copper/helpers/save-blob-as-file';
import { Loader, Table, TableEmptyRow, Tbody, Thead, useSnackBar } from '@copper/ui-kit';
import { formatCurrency, getErrorData } from '@copper/utils';
import { normalizeDryRunResult } from '../../helpers';
import { DryOrderRow } from './TableRows/DryOrderRow';
import { HeaderRow } from './TableRows/HeaderRow';
import s from './UploadStep.module.css';
const UploadStep = ({ onSuccess, initialState }) => {
    const dispatch = useDispatch();
    const { setDryOrdersBlanks, modalContext, setModalContext, dryOrdersBlanks, onDelete, selectedOrdersIds, selectedOrders, selectedOrdersList, onSelect, onUnselect, clearData, setResult, showIncompletedOnly, setShowIncompletedOnly } = useBulkOrder();
    const [isTemplateLoading, setIsTemplateLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [uploadErrorMessage, setUploadErrorMessage] = useState('');
    const { openSnackbar } = useSnackBar();
    const { organizationId } = useSelector(selectCurrentOrganization);
    const userId = useSelector(selectUserId);
    const hiddenInputLabelRef = useRef(null);
    const hiddenInputRef = useRef(null);
    const currenciesRates = useSelector(selectRates);
    const [machineState, machineSend] = useStateMachine({
        initial: initialState || 'fileInput',
        states: {
            fileInput: {
                on: { TO_UPLOAD: 'upload' }
            },
            upload: { on: { SUCCESS: 'parsedData', FAILURE: 'failure' } },
            failure: { on: { TO_UPLOAD: 'upload' } },
            parsedData: {
                on: {
                    TO_UPLOAD: 'upload',
                    REQUIRE_CONFIRM: 'confirmWithPassword',
                    SUCCESS: 'result',
                    CLEAR: 'fileInput',
                    FAILURE: 'failure'
                }
            },
            confirmWithPassword: { on: { SUCCESS: 'result', FAILURE: 'failure' } },
            result: {}
        }
    });
    const incompleteOrderIds = useMemo(() => Object.values(dryOrdersBlanks).reduce((acc, order) => (order?.statusDescription ? acc.concat(order.orderId) : acc), []), [dryOrdersBlanks]);
    const ordersList = useMemo(() => {
        if (showIncompletedOnly) {
            return Object.values(pick(dryOrdersBlanks, incompleteOrderIds));
        }
        return Object.values(dryOrdersBlanks);
    }, [dryOrdersBlanks, showIncompletedOnly, incompleteOrderIds]);
    const allOrdersIds = useMemo(() => Object.keys(dryOrdersBlanks), [dryOrdersBlanks]);
    const handleTemplateDownload = async () => {
        try {
            setIsTemplateLoading(true);
            const { fileData, fileId } = await dispatch(downloadBulkTemplate({ organizationId, userId })).unwrap();
            const [fileName] = fileId.split('/').slice(-1);
            saveBlobAsFile(fileData, `bulk-settlement-template_${fileName}`);
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setIsTemplateLoading(false);
        }
    };
    const handleFileUpload = async (filelist) => {
        machineSend('TO_UPLOAD');
        if (filelist.length) {
            try {
                clearData();
                const parsedOrders = await dispatch(uploadBulkTemplate(filelist[0])).unwrap();
                setDryOrdersBlanks(normalizeDryRunResult(parsedOrders));
                machineSend('SUCCESS');
            }
            catch (error) {
                const errorMessage = getErrorData(error);
                setUploadErrorMessage(errorMessage.message);
                machineSend('FAILURE');
            }
        }
    };
    const handleUploadButtonClick = () => {
        if (hiddenInputRef?.current?.value) {
            hiddenInputRef.current.value = '';
        }
        if (hiddenInputLabelRef.current) {
            hiddenInputLabelRef?.current.click();
        }
    };
    const handleHiddenInput = (event) => {
        if (event.currentTarget.files) {
            handleFileUpload(event.currentTarget.files);
        }
    };
    const handleToggleAll = (checked) => {
        if (checked) {
            showIncompletedOnly ? onSelect(incompleteOrderIds) : onSelect(allOrdersIds);
        }
        else {
            onUnselect(selectedOrdersIds);
        }
    };
    const handleIncompleteFilterToggle = (checked) => {
        if (!checked) {
            onUnselect(difference(allOrdersIds, incompleteOrderIds));
        }
        setShowIncompletedOnly(!checked);
    };
    const formattedTotalAmount = useMemo(() => {
        const ordersToSum = selectedOrdersIds.length ? selectedOrdersList : ordersList;
        const total = ordersToSum.reduce((acc, order) => {
            const rateInReporting = currenciesRates[order?.baseCurrency ?? ''] ?? '0';
            const amountInReporting = new BigNumber(order?.amount ?? 0).multipliedBy(rateInReporting);
            return new BigNumber(acc).plus(amountInReporting).toFixed();
        }, '0');
        return `${formatCurrency(total, { decimalPlaces: 2 })} ${reportingCurrency}`;
    }, [selectedOrdersIds, dryOrdersBlanks]);
    const handleSelectedDelete = () => {
        if (!selectedOrdersIds.length) {
            openSnackbar({
                type: 'error',
                message: 'No selected items. Select items to delete.'
            });
            return;
        }
        const { orderId = '' } = modalContext || {};
        if (orderId in selectedOrders) {
            setModalContext();
        }
        onDelete(selectedOrdersIds);
        if (selectedOrdersIds.length === allOrdersIds.length) {
            machineSend('CLEAR');
        }
    };
    const handleSendClick = async () => {
        if (!selectedOrdersIds.length) {
            openSnackbar({
                type: 'error',
                message: 'Please select orders to send.'
            });
            return;
        }
        if (intersection(selectedOrdersIds, incompleteOrderIds).length) {
            openSnackbar({
                type: 'error',
                message: 'Please edit or delete incomplete instructions to continue.'
            });
            return;
        }
        setIsSubmitting(true);
        const ordersToSend = selectedOrdersList.map((order) => {
            const { orderId: externalOrderId, ...baseValues } = order;
            return {
                ...baseValues,
                externalOrderId,
                limitType: 'otc'
            };
        });
        try {
            const createdOrders = await dispatch(bulkOrderCreate(ordersToSend)).unwrap();
            machineSend('SUCCESS');
            setResult(createdOrders);
            onSuccess();
        }
        catch (err) {
            setUploadErrorMessage(getErrorData(err).message);
            machineSend('FAILURE');
        }
        finally {
            clearData();
            setIsSubmitting(false);
        }
    };
    return (_jsxs(BulkTableFrame, { onDownloadClick: handleTemplateDownload, onUploadClick: handleUploadButtonClick, isTemplateLoading: isTemplateLoading, selectedCountText: `${selectedOrdersIds.length}${Boolean(ordersList.length) ? ` of ${ordersList.length}` : ''}`, formattedTotalAmount: formattedTotalAmount, incompleteOrdersCount: incompleteOrderIds.length, incompletedOnly: showIncompletedOnly, onSwitchToggle: handleIncompleteFilterToggle, switchDisabled: machineState.value !== 'parsedData', onDeleteClick: handleSelectedDelete, onSubmitClick: handleSendClick, isSubmitting: isSubmitting, children: [_jsx("label", { ref: hiddenInputLabelRef, children: _jsx("input", { hidden: true, ref: hiddenInputRef, type: "file", onInput: handleHiddenInput }) }), _jsxs(Table, { noBottomRounding: true, view: "green", className: cn(machineState.value !== 'parsedData' && s.tableFullHeight), children: [_jsx(Thead, { children: _jsx(HeaderRow, { handleToggleAll: handleToggleAll, ordersListLength: ordersList.length }) }), _jsxs(Tbody, { children: [machineState.value === 'fileInput' && (_jsx(FileDropZone, { onChange: handleFileUpload, onDownloadClick: handleTemplateDownload, onUploadClick: handleUploadButtonClick, isTemplateLoading: isTemplateLoading })), machineState.value === 'upload' && (_jsx(TableEmptyRow, { children: _jsx(Loader, { view: "tool", text: "Uploading..." }) })), machineState.value === 'failure' && (_jsx(UploadFailure, { message: uploadErrorMessage, onButtonClick: handleUploadButtonClick })), machineState.value === 'parsedData' &&
                                ordersList.map((order) => _jsx(DryOrderRow, { order: order }, order.orderId))] })] })] }));
};
export { UploadStep };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import useStateMachine from '@cassiozen/usestatemachine';
import { getPopupVaultTitle } from '@copper/components/AddAccountPopup/VaultForm/helpers';
import { Popup, PopupHeader } from '@copper/ui-kit';
import { PopupContent } from '@copper/ui-kit/Popup/PopupContent';
import { ThirdPartyForm } from '@copper/components/AddAccountPopup/ThirdPartyForm';
import { ClearloopForm } from './ClearloopForm';
import { ClearloopResult } from './ClearloopResult';
import { ExchangeForm } from './ExchangeForm';
import { ExchangeResult } from './ExchangeResult';
import { Initial } from './Initial';
import { TradingForm } from './TradingForm';
import { VaultForm } from './VaultForm';
import { VaultTypes } from './VaultTypes';
const AddAccountPopup = ({ onClose }) => {
    const [vaultPortfolioType, setVaultPortfolioType] = useState();
    const [isDefiVault, setIsDefiVault] = useState(false);
    const [connectedPortfolio, setConnectedPortfolio] = useState();
    const [state, send] = useStateMachine({
        initial: 'initial',
        states: {
            initial: {
                on: {
                    VAULT_TYPES: 'vaultTypes',
                    EXCHANGE_FORM: 'exchangeForm',
                    CLEARLOOP_FORM: 'clearloopForm',
                    TRADING_FORM: 'tradingForm',
                    THIRD_PARTY_FORM: 'thirdPartyForm'
                }
            },
            vaultTypes: {
                on: { BACK: 'initial', VAULT_FORM: 'vaultForm' }
            },
            vaultForm: { on: { BACK: 'vaultTypes' } },
            clearloopForm: {
                on: { BACK: 'initial', RESULT: 'clearloopResult' }
            },
            exchangeForm: { on: { BACK: 'initial', RESULT: 'exchangeResult' } },
            exchangeResult: {},
            clearloopResult: {},
            tradingForm: {
                on: { BACK: 'initial', RESULT: 'tradingResult' }
            },
            tradingResult: {},
            thirdPartyForm: { on: { BACK: 'initial' } }
        }
    });
    const getTitle = () => {
        switch (state.value) {
            case 'initial':
                return 'Create and connect accounts';
            case 'vaultTypes':
                return 'Choose your vault type';
            case 'vaultForm':
                return getPopupVaultTitle({ portfolioType: vaultPortfolioType, isDefiVault });
            case 'exchangeForm':
                return 'Connect exchange account';
            case 'clearloopForm':
                return 'Connect ClearLoop account';
            case 'tradingForm':
                return 'Create trading account';
            case 'thirdPartyForm':
                return 'Create third-party account';
        }
    };
    return (_jsxs(Popup, { onClose: onClose, children: [_jsx(PopupHeader, { title: getTitle(), onBack: state.value === 'initial' ? undefined : () => send('BACK') }), state.value === 'initial' && _jsx(Initial, { onNext: send }), state.value === 'vaultTypes' && (_jsx(VaultTypes, { onNext: ({ portfolioType, isDefiVault = false }) => {
                    setIsDefiVault(isDefiVault);
                    setVaultPortfolioType(portfolioType);
                    send('VAULT_FORM');
                } })), state.value === 'vaultForm' && (_jsx(VaultForm, { isDefiVault: isDefiVault, portfolioType: vaultPortfolioType, onSuccess: onClose })), state.value === 'exchangeForm' && (_jsx(ExchangeForm, { onNext: (portfolio) => {
                    setConnectedPortfolio(portfolio);
                    send('RESULT');
                } })), state.value === 'clearloopForm' && (_jsx(ClearloopForm, { onNext: (portfolio) => {
                    setConnectedPortfolio(portfolio);
                    send('RESULT');
                } })), state.value === 'exchangeResult' && (_jsx(PopupContent, { children: _jsx(ExchangeResult, { onSuccess: onClose, portfolioId: connectedPortfolio?.portfolioId }) })), state.value === 'clearloopResult' && (_jsx(PopupContent, { children: _jsx(ClearloopResult, { onSuccess: onClose, portfolioId: connectedPortfolio?.portfolioId }) })), state.value === 'tradingForm' && _jsx(TradingForm, { onSuccess: onClose }), state.value === 'thirdPartyForm' && _jsx(ThirdPartyForm, { onSuccess: onClose })] }));
};
export { AddAccountPopup };

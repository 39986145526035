import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { computeExplorerLink, getExplorerUrl, isEotcTrade, isSettle, useBlockHeightInExplorerLink } from '@copper/helpers';
import { Button, ButtonIcon, IconClipboard, Tooltip, useSnackBar } from '@copper/ui-kit';
import { copy } from '@copper/utils';
import s from './ExplorerLink.module.css';
const getOrderMainCurrency = (order) => {
    if ('depositId' in order) {
        return order.mainCurrency;
    }
    if (order.portfolioType === 'settlement-lite') {
        if (order.orderType === 'sell')
            return order.quoteMainCurrency;
        return order.mainCurrency;
    }
    if (order.orderType === 'buy')
        return order.quoteMainCurrency;
    return order.mainCurrency;
};
const getOrderCurrency = (order) => {
    if ('depositId' in order) {
        return order.currency;
    }
    return order.baseCurrency;
};
const getTransactionId = (order, mainCurrency, tokens) => {
    if (mainCurrency && useBlockHeightInExplorerLink(mainCurrency, tokens)) {
        if ('depositId' in order) {
            return order.blockNumber;
        }
        else {
            return order.extra?.blockchain?.blockHeight;
        }
    }
    if ('transactionId' in order) {
        return order.transactionId;
    }
    if ('depositId' in order) {
        return;
    }
    if (isEotcTrade(order) || isSettle(order)) {
        return order.extra?.transactionId;
    }
    // External CL, Funds in
    if (order.extra?.depositTransactionId) {
        return order.extra.depositTransactionId;
    }
    return order?.extra?.transactionId;
};
export const ExplorerLink = ({ order }) => {
    const { tokens } = useSelector(selectCurrencies);
    const { openSnackbar } = useSnackBar();
    const mainCurrency = getOrderMainCurrency(order);
    const transactionId = getTransactionId(order, mainCurrency, tokens);
    const currency = getOrderCurrency(order);
    if (!transactionId ||
        !mainCurrency ||
        ('orderType' in order &&
            order.orderType === 'earn-reward' &&
            tokens[`${currency}-${mainCurrency}`]?.tags?.includes('non-transactional-reward'))) {
        return null;
    }
    return (_jsxs("div", { className: s.button, children: [_jsx(Tooltip, { label: `ID: ${transactionId}`, placement: "top", children: _jsx("a", { href: computeExplorerLink({
                        hashId: transactionId,
                        explorerUrl: getExplorerUrl(tokens, mainCurrency)
                    }), target: "_blank", rel: "noreferrer", children: _jsx(Button, { view: "secondary", text: "View on block explorer", rightSection: _jsx("div", { className: s.explorerButtonDummy }) }) }) }), _jsx("div", { className: s.copyIconWrapper, children: _jsx(ButtonIcon, { view: "ghost", tooltipLabel: "Copy transaction ID", onClick: (e) => {
                        e.preventDefault();
                        copy(transactionId, () => openSnackbar({ type: 'success', message: 'Copied' }));
                    }, children: _jsx(IconClipboard, {}) }) })] }));
};

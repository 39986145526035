import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { DayPicker } from 'react-day-picker';
import cn from 'classnames';
import { Caption } from './Caption';
import styles from 'react-day-picker/dist/style.module.css';
import s from './DatePicker.module.css';
export const DATE_FORMAT = 'dd.MM.yyyy';
export const TIME_FORMAT = 'HH:mm';
const DatePicker = ({ mode = 'range', value, onChange, singleValue, onSingleChange, captionRef, fromDate, toDate, view = 'primary' }) => {
    const classNames = {
        ...styles,
        table: cn(s.table, s[`table_${view}`]),
        cell: s.cell,
        button: s.button,
        day: s.day,
        day_outside: s.dayOutside,
        day_selected: s.selected,
        head_cell: s.headCell,
        day_range_start: s.rangeStart,
        day_range_middle: s.rangeMiddle,
        day_range_end: s.rangeEnd,
        month: s.month
    };
    // Workaround for typescript err
    if (mode === 'single') {
        return (_jsx(DayPicker, { mode: "single", toYear: new Date().getFullYear() + 10, showOutsideDays: true, weekStartsOn: 1, selected: singleValue, onSelect: onSingleChange, fromDate: fromDate, toDate: toDate, components: {
                Caption: (props) => _jsx(Caption, { ...props, captionRef: captionRef, view: view })
            }, classNames: classNames }));
    }
    return (_jsx(DayPicker, { mode: "range", showOutsideDays: true, fromYear: new Date().getFullYear() - 10, toYear: new Date().getFullYear() + 5, weekStartsOn: 1, selected: value, onSelect: onChange, fromDate: fromDate, toDate: toDate, components: {
            Caption: (props) => _jsx(Caption, { ...props, captionRef: captionRef, view: view })
        }, classNames: classNames }));
};
export { DatePicker };

import { format } from 'date-fns';
import { calculateReportingValue } from '@copper/helpers';
import { formatCurrency, formatFiat } from '@copper/utils';
export const formatFeeEstimatedTime = (value) => {
    const [hours = 0, minutes = 0, seconds = 0] = format(Number(value), 'H:m:s').split(':');
    return Boolean(Number(value))
        ? `≈${Boolean(Number(hours)) ? ` ${hours}h` : ''}${Boolean(Number(minutes)) ? ` ${minutes}m` : ''}${Boolean(Number(seconds)) ? ` ${seconds}s` : ''}`
        : value;
};
const baseFeesLevelsDict = [
    {
        label: 'Slow',
        value: 'low'
    },
    {
        label: 'Average',
        value: 'medium'
    },
    {
        label: 'Fast',
        value: 'high'
    }
];
const feesFieldsByCurrency = {
    BTC: [
        {
            key: 'feePerByte',
            label: 'Fee Per Byte',
            editable: true,
            unit: 'sat/B'
        }
    ],
    ETH: [
        {
            key: 'gasPriceGwei',
            label: 'Gas price',
            editable: true,
            unit: 'GWEI'
        },
        {
            key: 'gasLimit',
            label: 'Gas limit (units)'
        }
    ]
};
const commonFeesFields = [
    {
        key: 'estimatedTime',
        label: 'Estimated time'
    },
    {
        key: 'fee',
        label: 'Estimated fees'
    }
];
const getEstimatedFeesFields = (mainCurrency = '') => {
    const uniqForCurrency = feesFieldsByCurrency[mainCurrency] || [];
    return uniqForCurrency.concat(commonFeesFields);
};
export const composeEstimatedFeesData = (mainCurrency = '', data, rateInReporting = '0') => getEstimatedFeesFields(mainCurrency).map((field) => {
    const fieldValue = data?.[field.key] ?? '0';
    switch (field.key) {
        case 'estimatedTime': {
            return {
                ...field,
                value: formatFeeEstimatedTime(fieldValue)
            };
        }
        case 'fee': {
            return {
                ...field,
                label: field.label,
                value: `${formatCurrency(fieldValue, { decimalPlaces: 8 })} ${data ? (data.feeCurrency ?? '') : (mainCurrency ?? '')}`,
                totalInReporting: formatFiat(calculateReportingValue(fieldValue, rateInReporting))
            };
        }
        default:
            return {
                ...field,
                value: fieldValue
            };
    }
});
export const getFeesLevelOptions = (isEditable = false) => !isEditable
    ? baseFeesLevelsDict
    : [
        ...baseFeesLevelsDict,
        {
            label: 'Custom',
            value: 'custom'
        }
    ];
export const checkIsFeesChoseable = (fromPortfolioType = '', isFiat = false) => {
    if (isFiat || !fromPortfolioType) {
        return false;
    }
    return true;
};

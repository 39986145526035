import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cuid from 'cuid';
import useStateMachine from '@cassiozen/usestatemachine';
import { useTransferPortfolio } from '@copper/components/Forms/TransferPortfolio/helpers';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { selectTokenizedFiatCurrencies } from '@copper/entities/currencies/currencies-selector';
import { createOrder } from '@copper/entities/orders/orders-reducer';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { useRoutingContext } from '@copper/hooks';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { OxygenWithdrawForm } from './Form';
import { Result } from './Result';
import { Summary } from './Summary';
import { validationSchema } from './helpers';
import s from './Withdraw.module.css';
const stepMap = {
    form: 1,
    summary: 2,
    result: 3
};
const OxygenWithdraw = () => {
    const dispatch = useDispatch();
    const { route } = useRoutingContext();
    const { bankAccounts = [] } = useSelector(selectCurrentOrganization);
    const getSelectedPortfolio = useTransferPortfolio();
    const currencies = useSelector(selectTokenizedFiatCurrencies);
    const [transactionErrorMessage, setTransactionErrorMessage] = useState();
    const [transaction, setTransaction] = useState();
    const { setTopPanel } = useToolbar();
    const form = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(validationSchema)
    });
    const [state, send] = useStateMachine({
        initial: 'form',
        states: {
            form: {
                on: { NEXT_STEP: 'summary' }
            },
            summary: {
                on: { NEXT_STEP: 'result', BACK_STEP: 'form', RESET: 'form' }
            },
            result: { on: { RESET: 'form', REPEAT: 'form' } }
        }
    });
    useEffect(() => {
        if (route?.name === 'oxygen-withdraw') {
            send('RESET');
            const data = route?.data;
            const defaultValues = {
                amount: data?.amount,
                currency: currencies.find((item) => item.currency === data?.currencyId),
                mainCurrency: data?.mainCurrencyId,
                origin: getSelectedPortfolio(data?.portfolioId),
                destination: bankAccounts.find((bankAccount) => bankAccount?.bankAccountId === data?.toBankAccountId)
            };
            form.reset(defaultValues);
        }
    }, [route]);
    useEffect(() => {
        let subTitle = `Step ${stepMap[state.value]} of ${Object.keys(stepMap).length}`;
        if (state.value === 'result') {
            subTitle = transactionErrorMessage ? 'Failed' : 'Transaction created';
        }
        setTopPanel({
            title: 'Withdraw',
            subTitle,
            onBack: state.value === 'summary' ? () => send({ type: 'BACK_STEP' }) : undefined
        });
    }, [state, send, setTopPanel, transactionErrorMessage]);
    const handleFormSend = form.handleSubmit(async () => {
        const formValues = form.getValues();
        const { destination } = formValues;
        const data = {
            externalOrderId: cuid(),
            orderType: 'withdraw',
            portfolioId: formValues?.origin?.portfolioId ?? '',
            baseCurrency: formValues.currency?.currency ?? '',
            mainCurrency: formValues.mainCurrency,
            amount: formValues.amount,
            description: formValues.description,
            toBankAccountId: destination && 'bankAccountId' in destination ? destination.bankAccountId : undefined
        };
        setTransactionErrorMessage(undefined);
        try {
            const result = await dispatch(createOrder(data)).unwrap();
            setTransaction(result);
        }
        catch (err) {
            setTransactionErrorMessage(getErrorData(err).message);
        }
        finally {
            send({ type: 'NEXT_STEP' });
        }
    });
    return (_jsxs("div", { className: s.container, children: [state.value === 'form' && (_jsx(OxygenWithdrawForm, { form: form, onSubmit: () => {
                    send({ type: 'NEXT_STEP' });
                }, formId: "oxygen-withdraw" })), state.value === 'summary' && (_jsx(Summary, { data: form.getValues(), isSubmitting: form.formState.isSubmitting, onNext: handleFormSend })), state.value === 'result' && (_jsx(Result, { transaction: transaction, formData: form.getValues(), onDone: () => {
                    send({ type: 'RESET' });
                }, errorMessage: transactionErrorMessage }))] }));
};
export { OxygenWithdraw };

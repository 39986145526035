import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updateWallets } from '@copper/entities/wallets/wallets-reducer';
import { useSocketsSubscribe } from './useSocketsSubscribe';
const useBalanceSubscription = (orgId, active) => {
    const dispatch = useDispatch();
    const balanceQueue = useRef({});
    useEffect(() => {
        const interval = setInterval(() => {
            const wallets = Object.values(balanceQueue.current);
            if (wallets.length) {
                dispatch(updateWallets(wallets));
                balanceQueue.current = {};
            }
        }, 1000);
        return () => {
            balanceQueue.current = {};
            clearInterval(interval);
        };
    }, []);
    const updateBalanceQueue = (wallets) => {
        wallets.forEach((wallet) => {
            const balance = balanceQueue.current[wallet.walletId];
            if (!balance || (balance?.updatedAt ?? 0) < (wallet?.updatedAt ?? 0)) {
                balanceQueue.current[wallet.walletId] = wallet;
            }
        });
    };
    useSocketsSubscribe({
        uri: '/wallets',
        params: {
            organizationId: orgId
        },
        active,
        onUpdate: (data) => {
            updateBalanceQueue(data.wallets);
        }
    });
};
export { useBalanceSubscription };

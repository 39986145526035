import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrenciesEntities } from '@copper/entities/currencies/currencies-selector';
import { fetchDepositTargets } from '@copper/entities/depositTargets/depositTargets-actions';
import { isCurrencyFiat } from '@copper/helpers';
const cache = new Map();
export const useFetchDepositTargets = ({ portfolioId, currency, mainCurrency, depositTargetId, enabled = true, targetType = 'direct' }) => {
    const currenciesEntities = useSelector(selectCurrenciesEntities);
    const [depositTarget, setDepositTarget] = useState();
    useEffect(() => {
        const fetchDepositTarget = async () => {
            if (!(portfolioId &&
                currency &&
                mainCurrency &&
                !isCurrencyFiat(currenciesEntities, currency)) &&
                !depositTargetId) {
                return;
            }
            const cacheKey = `${portfolioId}_${currency}_${mainCurrency}${depositTargetId ? `_${depositTargetId}` : ''}`;
            if (cache.has(cacheKey)) {
                return setDepositTarget(cache.get(cacheKey));
            }
            const depositTargets = await fetchDepositTargets({
                portfolioId,
                currency,
                mainCurrency,
                depositTargetId,
                status: 'enabled',
                targetType: depositTargetId ? undefined : targetType
            });
            const depositTarget = depositTargets.find(({ depositTargetId: id }) => id === depositTargetId) ??
                depositTargets[0];
            cache.set(cacheKey, depositTarget);
            setDepositTarget(depositTarget);
        };
        if (enabled) {
            fetchDepositTarget();
        }
    }, [portfolioId, currency, mainCurrency, enabled]);
    return depositTarget;
};

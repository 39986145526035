import { request } from '@copper/utils';
export const createUser = (data, twoFaCode) => request.post('/users', data, {
    headers: {
        'X-2FA-Code': twoFaCode
    }
});
export const activateUser = (userId, data) => request.patch(`/users/${userId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.setup-credentials+json'
    }
});
export const deleteUser = (userId, twoFaCode) => request.delete(`/users/${userId}`, {
    headers: {
        'X-2FA-Code': twoFaCode
    }
});
export const changePassword = (userId, data) => request.patch(`/users/${userId}`, data, {
    headers: {
        Authorization: `Basic ${data.oldPassword}`,
        ...(data.twoFaCode ? { 'X-2FA-Code': data.twoFaCode } : {}),
        'Content-Type': 'application/vnd.change-password+json'
    }
});
export const getUser = (params) => {
    const { userId, ...queryParams } = params;
    return request.get(`/users/${userId}`, { params: queryParams });
};
export const getUserActivities = ({ userId, params, headers }) => request.get(`/users/${userId}/activities`, {
    params,
    headers
});
export const resetCredentials = (userId, data) => request.patch(`/users/${userId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.reset-credentials+json'
    }
});
export const resendActivationLink = (organizationId, userId) => request.patch(
//in the future BE will replace with api endpoint with `/users/${userId}`,
`/organizations/${organizationId}/members/${userId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.resend-activation-link+json'
    }
});
export const resendActivationCode = (userId) => request.patch(`/users/${userId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.resend-activation-code+json'
    }
});
export const resetTwoFa = (userId, twoFaCode) => request.patch(`/users/${userId}`, {}, {
    headers: {
        'X-2FA-Code': twoFaCode,
        'Content-Type': 'application/vnd.reset-2fa+json'
    }
});
export const resetPassword = (userId, twoFaCode) => request.patch(`/users/${userId}`, {}, {
    headers: {
        'X-2FA-Code': twoFaCode,
        'Content-Type': 'application/vnd.reset-password+json'
    }
});
export const getUserWithPermissions = (userId) => request.get(`/users/${userId}?includePermissions=true`);
export const getUserWithProfile = (userId) => request.get(`/users/${userId}?includeUserProfile=true`);
export const getUserZendeskWidgetToken = (userId) => request.post(`/users/${userId}/tokens`, {}, {
    headers: {
        'Content-Type': 'application/vnd.create-zendesk-chat-jwt-token+json'
    }
});
export const getUserZendeskSSOToken = (userId) => request.post(`/users/${userId}/tokens`, {}, {
    headers: {
        'Content-Type': 'application/vnd.create-zendesk-sso-jwt-token+json'
    }
});
export const getUserIdentities = (userId, config) => request.get(`/users/${userId}/identities`, config);
export const createApiKey = (userId, apikey, twoFaCode) => request.post(`/users/${userId}/identities`, apikey, {
    headers: {
        'X-2FA-Code': twoFaCode,
        'Content-Type': 'application/vnd.create-apikey+json'
    }
});
export const createPasskeyParams = (userId, target) => request.post(`/users/${userId}/identities`, { target }, {
    headers: {
        'Content-Type': 'application/vnd.create-passkey-params+json'
    }
});
export const createPasskey = (userId, { twoFaCode, ...data }) => request.post(`/users/${userId}/identities`, data, {
    headers: {
        'X-2FA-Code': twoFaCode,
        'Content-Type': 'application/vnd.create-passkey+json'
    }
});
export const deletePasskey = (userId, passkey) => request.delete(`/users/${userId}/identities/passkey:${passkey}`);
export const updatePasskeyIdentity = (userId, passkey, data) => request.patch(`/users/${userId}/identities/passkey:${passkey}`, data, {
    headers: {
        'Content-Type': 'application/vnd.update-passkey+json'
    }
});
export const deleteApiKey = (userId, apikey) => request.delete(`/users/${userId}/identities/apikey:${apikey}`);
export const updateApiKey = (data, twoFaCode, userId, apiKey) => request.patch(`/users/${userId}/identities/apikey:${apiKey}`, data, {
    headers: {
        'X-2FA-Code': twoFaCode,
        'Content-Type': 'application/vnd.update-apikey+json'
    }
});
export const updateNotificationsSettings = (userId, data) => request.patch(`/users/${userId}/identities`, data, {
    headers: {
        'Content-Type': 'application/vnd.update-notifications-settings+json'
    }
});
export const createTelegramToken = (userId) => request.post(`/users/${userId}/identities`, {}, {
    headers: {
        'Content-Type': 'application/vnd.create-telegram-token+json'
    }
});
export const getFile = (userId, path, config) => request.get(`/users/${userId}/files/${path}`, config);
export const uploadProfileImage = (userId, data) => request.post(`/users/${userId}/images`, data);
export const deleteProfileImage = (userId, imageId) => request.delete(`/users/${userId}/images/${imageId}`);
export const updateUserInformation = (userId, data) => request.patch(`/users/${userId}`, data, {
    headers: {
        'Content-type': 'application/vnd.update-user-personal-info+json'
    }
});
export const getUsers = (params) => request.get('/users', { params });
export const updateUser = ({ userId, data, twoFaCode }) => request.patch(`/users/${userId}`, data, {
    headers: {
        'Content-type': 'application/vnd.update-user-info+json',
        'X-2FA-Code': twoFaCode
    }
});

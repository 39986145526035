import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { autoUpdate, flip, size as floatingSize, shift, useFloating } from '@floating-ui/react-dom';
import cn from 'classnames';
import Downshift from 'downshift';
import { IconKeyboardArrowDown, Loader, SearchField, useFormControl } from '@copper/ui-kit';
import { useComponentId } from '../hooks/useComponentId';
import { EmptyList } from './EmptyList';
import { Option } from './Option';
import { SelectedComponent } from './SelectedComponent';
import { filterItems, filterNestedItemsByArray, flattenNodes, getLeaves, getOpenedNodes, getParentKeys } from './helpers';
import { mapSelectViewToSearchView } from './types';
import s from './Select.module.css';
export const Select = ({ outerRef, items, itemsGroups, searchFields = [], emptySearchString, emptyDataString, placeholder = 'Select', itemToString, selected, onChange, onChangeMultiple, onFocus, disabled, getIcon, getTopRow, getBottomRow, getRightSection, getKey, getDisabledOption, initialOpenNodes, state, size = 'l', view = 'primary', fieldTheme = 'box', roundedSide = 'both', hideArrow = false, reversedStyles = false, renderMidSection, paddingLeft, testId = 'select', forceEmpty = false, dropwdownRef, searchPlaceholder = 'Search', searchType = 'weak', onSearch, isLoading = false, filterFunction = filterItems, getSectionAction, onScroll }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [openNodes, setOpenNodes] = useState([]);
    const buttonRef = useRef(null);
    const formControl = useFormControl();
    const componentId = useComponentId('select');
    useEffect(() => {
        if (initialOpenNodes) {
            const newOpenNodes = [...openNodes, ...initialOpenNodes];
            setOpenNodes(newOpenNodes);
        }
    }, [initialOpenNodes]);
    useEffect(() => {
        if (searchQuery && itemsGroups?.length) {
            itemsGroups.forEach(({ items = [], nestedItems = [] }) => {
                const searchResult = filterItems({
                    items: nestedItems.length > 0 ? flattenNodes(nestedItems) : items,
                    searchQuery,
                    searchFields,
                    searchType
                });
                const leavesParentIds = getParentKeys(getLeaves(filterNestedItemsByArray(nestedItems, searchResult, getKey)));
                setOpenNodes([...openNodes, ...leavesParentIds]);
            });
        }
    }, [searchQuery, componentId]);
    const { refs, floatingStyles } = useFloating({
        whileElementsMounted: autoUpdate,
        placement: 'bottom-start',
        elements: {
            reference: outerRef ? outerRef.current : undefined
        },
        middleware: [
            flip(),
            shift(),
            floatingSize({
                apply({ rects, elements }) {
                    Object.assign(elements.floating.style, {
                        width: `${rects.reference.width}px`
                    });
                }
            })
        ]
    });
    const stateReducer = (state, changes) => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.keyDownEnter:
            case Downshift.stateChangeTypes.clickItem:
                return {
                    ...changes,
                    highlightedIndex: state.highlightedIndex,
                    isOpen: true
                };
            default:
                return changes;
        }
    };
    const handleOnSelect = (item) => {
        if (onChangeMultiple && Array.isArray(selected) && item) {
            if (selected.some((el) => getKey(el) === getKey(item))) {
                onChangeMultiple(selected.filter((el) => getKey(el) !== getKey(item)));
            }
            else {
                onChangeMultiple([...selected, item]);
            }
        }
        else if (onChange) {
            onChange(item);
        }
    };
    const handleOnSearch = (value) => {
        setSearchQuery(value);
        onSearch && onSearch(value);
    };
    const handleKeyDown = useCallback((highlightedIndex) => (event) => {
        if (!itemsGroups?.length) {
            return;
        }
        if (event.key === 'ArrowRight') {
            const nodeKey = document
                .querySelector(`#${componentId}-item-${highlightedIndex}`)
                ?.getAttribute('data-node-key');
            if (nodeKey && !openNodes.includes(nodeKey)) {
                setOpenNodes([...openNodes, nodeKey]);
            }
        }
        if (event.key === 'ArrowLeft') {
            const nodeKey = document
                .querySelector(`#${componentId}-item-${highlightedIndex}`)
                ?.getAttribute('data-node-key');
            if (nodeKey && openNodes.includes(nodeKey)) {
                setOpenNodes(openNodes.filter((openNode) => openNode !== nodeKey));
            }
        }
    }, [itemsGroups, componentId, openNodes]);
    const generateItems = useCallback(() => {
        if (!itemsGroups?.length) {
            return [];
        }
        return itemsGroups
            .map(({ title, items = [], nestedItems = [] }) => {
            const searchResult = filterFunction({
                items: nestedItems.length > 0 ? flattenNodes(nestedItems) : items,
                searchQuery,
                searchFields,
                searchType
            });
            const openedNodes = getOpenedNodes(filterNestedItemsByArray(nestedItems, searchResult, getKey), openNodes, getKey, setOpenNodes);
            return {
                title,
                items: !nestedItems.length ? searchResult : openedNodes
            };
        })
            .filter(({ items, title }) => Boolean(items?.length || !!getSectionAction?.(title)) && !forceEmpty);
    }, [itemsGroups, openNodes, getKey, searchQuery, searchFields]);
    const onlyItems = useMemo(() => filterFunction({ items: items ?? [], searchQuery, searchFields, searchType }), [items, searchFields, searchQuery, filterFunction]);
    const groupedItems = generateItems();
    return (_jsx(Downshift, { id: componentId, onSelect: handleOnSelect, onStateChange: (changes) => {
            if (changes.isOpen === false) {
                setSearchQuery('');
                onSearch && onSearch('');
                buttonRef.current?.focus();
            }
        }, selectedItem: Array.isArray(selected) ? undefined : (selected ?? null), itemToString: (item) => (item && itemToString ? itemToString(item) : ''), stateReducer: Array.isArray(selected) ? stateReducer : undefined, children: ({ getToggleButtonProps, getMenuProps, getItemProps, getInputProps, highlightedIndex, isOpen }) => (_jsxs("div", { className: s.container, "data-testid": testId, children: [_jsx("div", { ref: refs.setReference, children: _jsxs("button", { "data-opened": isOpen, "data-testid": testId ? `${testId}-button` : undefined, className: cn(s.field, s[`field_${fieldTheme}`], s[`view_${view}`], s[`size_${size}`], s[`rounded_${roundedSide}`], s[`state_${state || formControl.state}`], {
                            [s.fieldOpened]: isOpen,
                            [s.fieldEmpty]: !selected
                        }), ...getToggleButtonProps({
                            type: 'button',
                            ref: buttonRef,
                            disabled: !!disabled,
                            onKeyDown: handleKeyDown(highlightedIndex),
                            onFocus
                        }), children: [_jsx(SelectedComponent, { view: view, selected: selected, getIcon: getIcon, getKey: getKey, getTopRow: getTopRow, getBottomRow: getBottomRow, getRightSection: getRightSection, reversedStyles: reversedStyles, placeholder: placeholder, fieldTheme: fieldTheme }), !hideArrow && (_jsx("div", { className: cn(s.arrowIcon, {
                                    [s.arrowIconOpened]: isOpen
                                }), children: _jsx(IconKeyboardArrowDown, {}) }))] }) }), isOpen &&
                    ReactDOM.createPortal(_jsx("div", { ref: dropwdownRef, children: _jsx("div", { ref: refs.setFloating, className: s.dropdownContainer, style: floatingStyles, children: _jsxs("div", { ...getMenuProps(), className: cn(s.dropdown, s[`dropdown_view_${view}`]), children: [Boolean(searchFields.length) && (_jsx("div", { className: s.searchField, children: _jsx(SearchField, { autoFocus: true, view: mapSelectViewToSearchView[view], "data-testid": `${testId}-search-input`, ...getInputProps({
                                                onKeyDown: handleKeyDown(highlightedIndex)
                                            }), size: "l", placeholder: searchPlaceholder, value: searchQuery, onChange: handleOnSearch }) })), typeof renderMidSection === 'function' && (_jsx("div", { className: cn(s.midSection, s[`midSection_view_${view}`]), children: renderMidSection() })), isLoading && (_jsx("div", { className: s.loaderHolder, children: _jsx(Loader, { view: view, size: "m" }) })), !isLoading &&
                                        items !== undefined &&
                                        (onlyItems.length > 0 ? (_jsx("div", { className: s.optionsList, onScroll: onScroll, children: onlyItems.map((item, index) => (_jsx(Option, { view: view, data: item, isHighlighted: highlightedIndex === index, getIcon: getIcon, getKey: getKey, getTopRow: getTopRow, getBottomRow: getBottomRow, getRightSection: getRightSection, paddingLeft: paddingLeft, reversedStyles: reversedStyles, selected: selected, testId: testId, ...getItemProps({
                                                    item,
                                                    index,
                                                    disabled: getDisabledOption ? getDisabledOption(item) : false
                                                }) }, `option-${index}`))) })) : (_jsx(EmptyList, { type: searchQuery ? 'search' : 'data', emptyDataString: emptyDataString, emptySearchString: emptySearchString }))), !isLoading && itemsGroups !== undefined && (_jsx(_Fragment, { children: groupedItems.length > 0 ? (_jsx("div", { className: s.optionsList, onScroll: onScroll, children: groupedItems.reduce((result, section, sectionIndex) => {
                                                result.sections.push(_jsxs(Fragment, { children: [section.title && (_jsxs("div", { className: cn(s.sectionTitle, s[`sectionTitle_${view}`]), children: [section.title, getSectionAction && getSectionAction(section.title)] })), (section.items.length == 0 || forceEmpty) && (_jsx(EmptyList, { type: searchQuery ? 'search' : 'data', emptyDataString: emptyDataString, emptySearchString: emptySearchString })), section.items.map((item) => {
                                                            const index = result.itemIndex++;
                                                            return (_jsx(Option, { view: view, data: item, isHighlighted: highlightedIndex === index, selected: selected, getIcon: getIcon, getKey: getKey, getTopRow: getTopRow, getBottomRow: getBottomRow, paddingLeft: paddingLeft, reversedStyles: reversedStyles, testId: testId, ...getItemProps({
                                                                    item,
                                                                    index,
                                                                    disabled: getDisabledOption
                                                                        ? getDisabledOption(item)
                                                                        : false
                                                                }) }, getKey ? getKey(item) : `${sectionIndex}-${index}`));
                                                        })] }, `${section.title}-${sectionIndex}`));
                                                return result;
                                            }, { sections: [], itemIndex: 0 }).sections })) : (_jsx(EmptyList, { type: searchQuery ? 'search' : 'data', emptyDataString: emptyDataString, emptySearchString: emptySearchString })) }))] }) }) }), document.body)] })) }));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import cn from 'classnames';
import { TopNavBar } from '@copper/components';
import { useLayoutState } from '@copper/contexts/layout';
import { fetchCounterpartiesByOrg } from '@copper/entities/counterparties/counterparties-reducer';
import { updateCurrencyRates } from '@copper/entities/currencyRates/currencyRates-reducer';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { useBalanceSubscription } from '@copper/hooks/useBalanceSubscription';
import { useSocketsSubscribe } from '@copper/hooks/useSocketsSubscribe';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar';
import { Toolbar } from '@copper/components/Toolbar';
import { ProfileMenuContent } from '../ToolbarItems/ProfileMenuContent';
import { ToolbarMenu } from '../ToolbarItems/ToolbarMenu';
import { ToolbarPanelContent } from '../ToolbarItems/ToolbarPanelContent';
import s from './Layout.module.css';
const Layout = () => {
    const dispatch = useDispatch();
    const { organizationId } = useSelector(selectCurrentOrganization);
    const { isFullWidth, loadingLayout } = useLayoutState();
    useBalanceSubscription(organizationId, Boolean(organizationId && !loadingLayout));
    useSocketsSubscribe({
        uri: '/dictionaries/currency-prices',
        params: {
            organizationId
        },
        active: Boolean(organizationId && !loadingLayout),
        onUpdate: (data) => {
            dispatch(updateCurrencyRates(data?.marketPrices ?? []));
        }
    });
    useEffect(() => {
        if (organizationId && !loadingLayout) {
            dispatch(fetchCounterpartiesByOrg({
                organizationId: organizationId
            })).unwrap();
        }
    }, [organizationId, loadingLayout]);
    return (_jsxs("main", { className: s.container, children: [_jsx(Header, {}), !isFullWidth && (_jsx("aside", { className: s.sidebar, children: _jsx(Sidebar, {}) })), _jsxs("section", { className: cn(s.content, {
                    [s.fullWidth]: isFullWidth
                }), children: [_jsx(TopNavBar, {}), _jsx(Outlet, {}), _jsx(Toolbar, { menu: _jsx(ToolbarMenu, {}), profileMenu: _jsx(ProfileMenuContent, {}), children: _jsx(ToolbarPanelContent, {}) })] })] }));
};
export { Layout };

import { request } from '@copper/utils';
export const createClearloopAccount = (data) => request.post('/clearloop/requests', data);
export const getRiskClearloopExchanges = (organizationId) => request.get('/clearloop/exchanges', {
    params: {
        organizationId
    }
});
export const getClearloopBalances = (params) => request.get('/clearloop/balances', {
    params
});
export const getClearloopSettlements = (params) => request.get('/clearloop/settlements', {
    params: {
        sortDirection: 'desc',
        ...params
    }
});
export const getClearloopSettlement = ({ settlementId, organizationId }) => request.get(`/clearloop/settlements/${settlementId}?organizationId=${organizationId}`);
export const getClearloopMarginCalls = ({ organizationId, fromMillis, toMillis, limit, offset }) => request.get('/clearloop/margin-calls', {
    params: {
        organizationId,
        fromMillis,
        toMillis,
        limit,
        offset
    }
});
export const getClearloopHoldings = (organizationId) => request.get('/clearloop/holdings', {
    params: {
        organizationId
    }
});
export const updateClearLoopExchange = (exchangeId, data) => request.patch(`/clearloop/internal/exchanges/${exchangeId}`, data);
export const getClearloopExchanges = (organizationId) => request.get(`/clearloop/internal/exchanges`, {
    params: { organizationId }
});

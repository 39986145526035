import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import cn from 'classnames';
import { Collapse, IconArrowDown } from '@copper/ui-kit';
import { StakeCard } from '../../StakeCard';
import { assets } from './assets';
import { getScrollableParent } from './helpers';
import s from './Assets.module.css';
export const Assets = ({ activeAsset }) => {
    const ref = useRef({});
    const scrollableParentRef = useRef();
    useEffect(() => {
        const activeAssetRef = ref.current[activeAsset];
        activeAssetRef && handleScroll(activeAssetRef);
    }, [activeAsset]);
    const handleScroll = (ref) => {
        scrollableParentRef.current?.scrollTo({
            top: ref.offsetTop - 63,
            left: 0
        });
    };
    return (_jsx("div", { className: s.container, children: assets.map((asset) => (_jsx(Collapse, { open: activeAsset === asset.title, renderToggle: (isOpen, onToggleCollapse) => (_jsx("div", { ref: (el) => {
                    ref.current[asset.title] = el;
                    if (!scrollableParentRef.current) {
                        scrollableParentRef.current = getScrollableParent(el);
                    }
                }, children: _jsx(StakeCard, { hasDropdown: true, isDropdownOpen: isOpen, currency: asset.title, onClick: () => {
                        onToggleCollapse();
                    } }) })), children: _jsx("div", { className: s.assetQA, children: asset.qa.map((qa) => (_jsx("div", { className: s.collapsingContainer, children: _jsx(Collapse, { renderToggle: (isOpen, onToggleCollapse) => (_jsxs("button", { type: "button", className: s.collapsingTitle, onClick: onToggleCollapse, children: [_jsx("div", { className: s.questionTitle, children: qa.question }), _jsx("span", { className: cn(s.arrow, { [s.arrowOpen]: isOpen }), children: _jsx(IconArrowDown, {}) })] })), children: _jsx("div", { className: s.collapsedContent, children: qa.answer }) }) }, qa.question))) }) }, asset.id))) }));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { debounce } from 'lodash-es';
import { InfoList } from '@copper/components';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { createDryRunOrder } from '@copper/entities/orders/orders-actions';
import { calculateReportingValue, getCurrencyFeeByLevel, getNetworkFeesParams } from '@copper/helpers';
import { isVaultPortfolio } from '@copper/helpers/portfolios';
import { useInterval } from '@copper/hooks';
import { Alert, ChoiceGroup, Input } from '@copper/ui-kit';
import { formatCurrency, formatFiat, getErrorData } from '@copper/utils';
import { POLLING_INTERVAL } from '../constants';
import { checkIsFeesChoseable, composeEstimatedFeesData, getFeesLevelOptions } from './helpers';
import s from './NetworkFees.module.css';
const NetworkFees = ({ view = 'primary', invoice }) => {
    const currenciesRates = useSelector(selectRates);
    const { tokens } = useSelector(selectCurrencies);
    const { setValue, watch, getValues } = useFormContext();
    const { currency, mainCurrency, portfolio, feeLevel, estimatedFees, includeFeeInWithdraw, requestedNetworkFees } = watch();
    const [estimatedFeesError, setEstimatedFeesError] = useState();
    const [estimatedFeesWarning, setEstimatedFeesWarning] = useState();
    const [pollingIsRunning, setPollingIsRunning] = useState(false);
    const isTradingFees = portfolio?.portfolioType === 'trading';
    const isFeesChoseable = checkIsFeesChoseable(portfolio?.portfolioType, currency?.fiat);
    const isNetworkFees = isVaultPortfolio(portfolio?.portfolioType) &&
        Boolean(getNetworkFeesParams(tokens, mainCurrency).length);
    const feesLevelOptions = getFeesLevelOptions(isNetworkFees);
    const rateInReporting = currenciesRates[isTradingFees ? (currency?.currency ?? '') : (mainCurrency ?? '')];
    const tradingFees = getCurrencyFeeByLevel(tokens, feeLevel, currency?.currency, mainCurrency);
    useEffect(() => {
        if (!isNetworkFees) {
            if (feeLevel === 'custom') {
                setValue('feeLevel', 'low');
            }
            estimatedFeesError && setEstimatedFeesError(undefined);
        }
    }, [isNetworkFees]);
    useEffect(() => {
        setValue('includeFeeInWithdraw', false);
        setValue('feeLevel', 'low');
    }, []);
    const getRequestParams = () => {
        const { currency, mainCurrency, portfolio, includeFeeInWithdraw, requestedNetworkFees } = getValues();
        return {
            orderType: 'withdraw',
            portfolioId: portfolio?.portfolioId ?? '',
            baseCurrency: currency?.currency ?? '',
            mainCurrency: mainCurrency,
            amount: invoice.amount,
            toInvoiceId: invoice.invoiceId,
            includeFeeInWithdraw,
            requestedNetworkFees,
            feeLevel: feeLevel !== 'custom' ? feeLevel : undefined
        };
    };
    const debouncedCreateDryRunOrder = useCallback(debounce((requestParams) => createDryRunOrder(requestParams)
        .then((data) => {
        const estimatedFees = data.extra?.estimatedFees;
        setValue('estimatedFees', estimatedFees);
        setPollingIsRunning(true);
        setEstimatedFeesError(undefined);
        setEstimatedFeesWarning(undefined);
        if (data.warning) {
            setEstimatedFeesWarning(data.warning.message);
        }
    })
        .catch((err) => {
        setValue('estimatedFees', undefined);
        setEstimatedFeesError(getErrorData(err).message);
    }), 300), []);
    useEffect(() => {
        setPollingIsRunning(false);
        if (isNetworkFees && isFeesChoseable) {
            if (new BigNumber(invoice.amount ?? 0).gt(0) && mainCurrency) {
                debouncedCreateDryRunOrder(getRequestParams());
            }
            else {
                setValue('estimatedFees', undefined);
                setEstimatedFeesError(undefined);
            }
        }
        else {
            setEstimatedFeesError(undefined);
            setValue('estimatedFees', undefined);
            setValue('requestedNetworkFees', undefined);
        }
    }, [
        currency?.currency,
        mainCurrency,
        invoice,
        portfolio?.portfolioId,
        feeLevel,
        isNetworkFees,
        includeFeeInWithdraw,
        requestedNetworkFees,
        debouncedCreateDryRunOrder,
        isFeesChoseable
    ]);
    useInterval(() => {
        debouncedCreateDryRunOrder(getRequestParams());
    }, pollingIsRunning ? POLLING_INTERVAL : null);
    useEffect(() => {
        if (!isFeesChoseable) {
            setValue('feeLevel', 'low');
            setValue('requestedNetworkFees', undefined);
        }
    }, [isFeesChoseable]);
    useEffect(() => {
        if (requestedNetworkFees) {
            setValue('feeLevel', 'custom');
        }
    }, [requestedNetworkFees]);
    useEffect(() => {
        if (feeLevel !== 'custom') {
            setValue('requestedNetworkFees', undefined);
        }
    }, [feeLevel]);
    if (!isFeesChoseable) {
        return null;
    }
    const handleCustomFeeInputChange = (name) => (event) => {
        const { value = '' } = event.target;
        const regExp = mainCurrency === 'BTC' ? /^[0-9\\b]{1,4}$/ : /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
        if (value === '' || regExp.test(value)) {
            setValue('requestedNetworkFees', { [name]: value });
        }
    };
    const getFormLabel = (mainCurrency = '') => {
        const formLabel = {
            [mainCurrency]: 'Fee',
            BTC: 'Fee per byte',
            ETH: 'Gas fee'
        };
        return formLabel[mainCurrency] || '';
    };
    return (_jsxs(InfoList, { title: getFormLabel(mainCurrency), children: [isTradingFees && (_jsxs("div", { className: cn(s.row, s.tradingFees), children: [_jsx("div", { className: s.rowLabel, children: "Amount" }), _jsxs("div", { className: s.rowValue, children: [formatCurrency(tradingFees), " ", currency?.currency ?? '', ` (${formatFiat(calculateReportingValue(tradingFees, rateInReporting ?? '0'))})`] })] })), isNetworkFees && (_jsx("div", { className: s.networkFees, children: composeEstimatedFeesData(mainCurrency, estimatedFees, rateInReporting).map(({ label, value, key, totalInReporting, unit, editable = false }) => editable ? (_jsx(Input, { size: "l", view: view, rightSection: unit, value: feeLevel === 'custom' ? (requestedNetworkFees?.[key] ?? '') : value, onChange: handleCustomFeeInputChange(key) }, key)) : (_jsxs("div", { className: s.row, children: [_jsx("div", { className: s.rowLabel, children: label }), _jsxs("div", { className: s.rowValue, children: [value, " ", totalInReporting && ` (${totalInReporting})`] })] }, key))) })), _jsx("div", { className: s.toggleButtons, children: _jsx(ChoiceGroup, { name: "feeLevel", form: "separate", view: view, items: feesLevelOptions, value: feeLevel, getItemLabel: (item) => item.label, getItemKey: (item) => item.value, onChange: ({ newValue }) => setValue('feeLevel', newValue.value) }) }), estimatedFeesError && (_jsx("div", { className: s.alert, children: _jsx(Alert, { type: "error", message: `Attention: ${estimatedFeesError}` }) })), Boolean(feeLevel === 'custom' && estimatedFeesWarning) && (_jsx("div", { className: s.alert, children: _jsx(Alert, { type: "warning", message: `Attention: ${estimatedFeesWarning}` }) }))] }));
};
export { NetworkFees };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { CounterpartyLogo } from '@copper/components/Counterparty/CounterpartyNetworkCard/CounterpartyLogo';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { getImageSrcById } from '@copper/helpers';
import { IconSettings } from '@copper/ui-kit';
import { Clipboardable } from '@copper/ui-kit';
import s from './CurrentOrganization.module.css';
const CurrentOrganization = ({ onClick }) => {
    const { counterparty, organizationId, organizationName } = useSelector(selectCurrentOrganization);
    const { backgroundImageId = 'counterparties/backgrounds/defaults/1-v2.png', imageId } = counterparty ?? {};
    return (_jsx("div", { className: s.container, children: _jsxs("div", { className: s.cardContainer, children: [_jsx("div", { className: s.cardBackground, style: {
                        backgroundImage: `url(${getImageSrcById(backgroundImageId)})`
                    } }), _jsxs("div", { className: s.cardContent, children: [_jsx("div", { className: s.logoContainer, children: _jsx(CounterpartyLogo, { counterpartyName: organizationName, imageId: imageId, size: "s" }) }), _jsxs("div", { className: s.organisationContainer, children: [_jsx("div", { className: s.organisationName, children: organizationName }), _jsx("div", { className: s.organisationId, children: _jsx(Clipboardable, { truncateParams: [12, 12, 24], value: organizationId, align: "left" }) })] }), _jsxs("div", { className: s.settingsContainer, "data-testid": "organisation_settings", onClick: onClick, children: [_jsx("div", { className: s.settingsIcon, children: _jsx(IconSettings, {}) }), _jsx("div", { className: s.settingsText, children: "Organisation settings" })] })] })] }) }));
};
export { CurrentOrganization };

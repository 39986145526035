import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo, useState } from 'react';
import { omit, pick } from 'lodash-es';
const BulkOrderContext = createContext(undefined);
export const BulkOrderProvider = ({ children }) => {
    const [dryOrdersBlanks, setDryOrdersBlanks] = useState({});
    const [modalContext, setModalContext] = useState();
    const [selectedOrders, setSelectedOrders] = useState({});
    const [resultOrders, setResultOrders] = useState([]);
    const [showIncompletedOnly, setShowIncompletedOnly] = useState(false);
    const modalOrderData = useMemo(() => dryOrdersBlanks[modalContext?.orderId ?? ''], [modalContext, dryOrdersBlanks]);
    const handleDeleteOrders = (orderIds) => {
        if (orderIds.includes(modalContext?.orderId ?? '')) {
            setModalContext(undefined);
        }
        setDryOrdersBlanks(omit(dryOrdersBlanks, orderIds));
        unselectOrders(orderIds);
    };
    const handleOrderUpdate = (dryOrder) => {
        setDryOrdersBlanks({
            ...dryOrdersBlanks,
            [dryOrder.orderId]: dryOrder
        });
    };
    const selectOrders = (orderIds) => {
        const newSelected = orderIds.reduce((acc, orderId) => ({ ...acc, [orderId]: true }), {});
        setSelectedOrders({
            ...selectedOrders,
            ...newSelected
        });
    };
    const selectedOrdersIds = Object.keys(selectedOrders);
    const selectedOrdersList = useMemo(() => Object.values(pick(dryOrdersBlanks, selectedOrdersIds)), [selectedOrdersIds, dryOrdersBlanks]);
    const unselectOrders = (orderIds) => setSelectedOrders(omit(selectedOrders, orderIds));
    const clearData = () => {
        setModalContext(undefined);
        setSelectedOrders({});
        setDryOrdersBlanks({});
        setShowIncompletedOnly(false);
    };
    return (_jsx(BulkOrderContext.Provider, { value: {
            dryOrdersBlanks,
            setDryOrdersBlanks,
            modalContext,
            setModalContext,
            modalOrderData,
            onDelete: handleDeleteOrders,
            onUpdate: handleOrderUpdate,
            onSelect: selectOrders,
            onUnselect: unselectOrders,
            selectedOrders,
            selectedOrdersIds,
            selectedOrdersList,
            clearData,
            setResult: setResultOrders,
            resultOrders: resultOrders,
            setShowIncompletedOnly,
            showIncompletedOnly
        }, children: children }));
};
export const useBulkOrder = () => {
    const context = useContext(BulkOrderContext);
    if (context === undefined) {
        throw new Error('useBulkOrder must be used within a BulkOrderProvider');
    }
    return context;
};

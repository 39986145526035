import { createSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { omit } from 'lodash-es';
import { selectTokens } from '@copper/entities/currencies/currencies-selector';
export const selectStakingCurrencies = createSelector(selectTokens, (tokens) => tokens.filter((token) => ['stake', 'importance-transfer'].some((tag) => token?.tags?.includes(tag)) &&
    token.currency !== 'BNB'));
export const selectActiveStakes = (store) => store.stakes.activeStakes;
export const selectPendingStakes = (store) => store.stakes.pendingStakes;
export const selectOutstandingStakes = (store) => store.stakes.outstandingStakes;
export const selectClaimableStakes = (store) => store.stakes.claimableStakes;
export const selectStakeCounters = (store) => store.stakes.counters;
export const selectAggregatedStakes = (store) => store.stakes.aggregatedStakes;
export const selectAggregatedStakesByCurrency = createSelector(selectAggregatedStakes, (aggregatedStakes) => {
    const aggregatedByCurrency = aggregatedStakes.reduce((acc, aggregatedStake) => {
        const currentValue = acc[aggregatedStake.currency];
        if (currentValue) {
            return {
                ...acc,
                [aggregatedStake.currency]: {
                    ...currentValue,
                    totalAmount: new BigNumber(currentValue.totalAmount)
                        .plus(aggregatedStake.totalAmount)
                        .toFixed(),
                    totalAmountReporting: new BigNumber(currentValue.totalAmountReporting)
                        .plus(aggregatedStake.totalAmountReporting)
                        .toFixed()
                }
            };
        }
        return {
            ...acc,
            [aggregatedStake.currency]: omit(aggregatedStake, 'portfolioId')
        };
    }, {});
    return Object.values(aggregatedByCurrency);
});

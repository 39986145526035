import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as accountActivitiesApi from '@copper/api/accountActivities';
import { getUserActivities } from '@copper/api/users';
import { normalizeAccountActivities } from '@copper/entities/accountActivities/accountActivities-normalize';
export const getAccountActivities = createAsyncThunk('accountActivities/getAccountActivities', async ({ organizationId, params }, thunkAPI) => {
    try {
        const response = await accountActivitiesApi.getAccountActivities(organizationId, params);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const getAdminUserActivities = createAsyncThunk('accountActivities/getAdminUserActivities', async (data, thunkAPI) => {
    try {
        const response = await getUserActivities(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const accountActivitiesSlice = createSlice({
    name: 'accountActivities',
    initialState: {
        entities: {},
        activityCategories: {},
        activityTypes: {}
    },
    reducers: {},
    extraReducers: ({ addMatcher }) => {
        addMatcher(isAnyOf(getAccountActivities.fulfilled, getAdminUserActivities.fulfilled), (state, action) => {
            const { entities, activityCategories, activityTypes } = normalizeAccountActivities(action.payload);
            state.entities = entities;
            state.activityCategories = activityCategories;
            state.activityTypes = activityTypes;
        });
    }
});
const { reducer } = accountActivitiesSlice;
export const accountActivities = reducer;

import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import '@copper/assets/styles/common.css';
import { CheckPermissionsProvider } from '@copper/contexts/checkPermissions';
import { resetAuth } from '@copper/entities/auth/auth-reducer';
import { RoutingContextProvider } from '@copper/helpers/routing-context';
import { WebsocketContextProvider } from '@copper/helpers/websocket-context';
import { sendSyncLogout } from '@copper/hooks';
import { createStore } from '@copper/store';
import { DARK_THEME, SnackBarProvider, Theme } from '@copper/ui-kit';
import { loadData } from '@copper/utils/localstorage';
import { setupAxiosInterceptors } from '@copper/utils/request';
import { getScrollBarWidth } from '@copper/utils/window';
import { router } from './router';
const store = createStore(loadData(['auth', 'user']));
const handleAuthError = () => {
    store.dispatch(resetAuth());
    sendSyncLogout();
};
setupAxiosInterceptors(store, handleAuthError);
if (getScrollBarWidth() > 0) {
    document.documentElement.classList.add('scrollbar');
}
const root = createRoot(document.querySelector('#root'));
root.render(_jsx(Provider, { store: store, children: _jsx(Theme, { theme: DARK_THEME, children: _jsx(SnackBarProvider, { children: _jsx(WebsocketContextProvider, { children: _jsx(CheckPermissionsProvider, { children: _jsx(RoutingContextProvider, { children: _jsx(RouterProvider, { router: router }) }) }) }) }) }) }));

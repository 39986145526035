import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from 'classnames';
import { copy, truncateStr } from '@copper/utils';
import { ButtonIcon } from '../ButtonIcon';
import { useSnackBar } from '../Snackbar/SnackbarContext';
import { Tooltip } from '../Tooltip';
import { IconClipboard } from '../icons';
import s from './Clipboardable.module.css';
export const Clipboardable = ({ value, align = 'right', truncateParams }) => {
    const { openSnackbar } = useSnackBar();
    return (_jsxs("div", { className: cn(s.container, s[`containerAlign_${align}`]), children: [_jsx("div", { className: cn(s.button, s[`buttonAlign_${align === 'right' ? 'left' : 'right'}`]), children: _jsx(ButtonIcon, { onClick: (event) => {
                        event.stopPropagation();
                        copy(value ?? '', () => openSnackbar({ message: 'Copied', type: 'success' }));
                    }, tooltipLabel: "Copy to clipboard", children: _jsx(IconClipboard, { width: 16, height: 16 }) }) }), truncateParams ? (_jsx(Tooltip, { type: "hint", label: value, children: truncateStr(value, ...truncateParams) })) : (value)] }));
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { refreshBearerToken, resetAuth, updateToken } from '@copper/entities/auth/auth-reducer';
import { selectAuth, selectIsAuthorized } from '@copper/entities/auth/auth-selector';
import { clearAuthData, setAuthorizationHeader } from '@copper/utils';
import { TypedBroadcastChannel } from '@copper/utils/typedBroadcastChannel';
import { differenceInMinutes } from 'date-fns';
import { debounce, random } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
const broadcastChannel = new TypedBroadcastChannel('SyncTokenChannel');
export const useTokenRefreshSync = (target) => {
    const dispatch = useDispatch();
    const { state: locationState } = useLocation();
    const { expiredAt } = useSelector(selectAuth);
    const isAuthorized = useSelector(selectIsAuthorized);
    const refreshTimerRef = useRef();
    const idleCheckTimerRef = useRef();
    const lastActiveAt = useRef(new Date());
    const [refreshIsActive, setRefreshIsActive] = useState(false);
    const shiftExpiredAt = useCallback((expiredAt) => {
        const untilExpire = (expiredAt ? Number(expiredAt) : 0) - Date.now();
        if (untilExpire >= 30000) {
            const randomShift = random(5, 30) * 1000;
            return untilExpire - randomShift;
        }
        return Math.max(random(0, untilExpire - 5000), 0);
    }, []);
    const scheduleTokenRefresh = (refreshAt) => {
        refreshTimerRef.current = setTimeout(async () => {
            broadcastChannel.postMessage({ type: 'tokenRequested' });
            const newToken = await dispatch(refreshBearerToken(target)).unwrap();
            if (newToken) {
                broadcastChannel.postMessage({
                    type: 'tokenUpdate',
                    token: newToken.token,
                    expiredAt: newToken.expiredAt
                });
            }
        }, refreshAt);
    };
    const scheduleCheckIdleTimer = () => {
        idleCheckTimerRef.current = setTimeout(() => {
            if (differenceInMinutes(new Date(), lastActiveAt.current) >= 120) {
                setAuthorizationHeader(null);
                clearAuthData();
                dispatch(resetAuth());
                broadcastChannel.postMessage({
                    type: 'syncLogout'
                });
            }
            else {
                scheduleCheckIdleTimer();
            }
        }, 30000);
    };
    const handleIdleReset = useCallback(debounce(() => {
        lastActiveAt.current = new Date();
        broadcastChannel.postMessage({
            type: 'resetIdle'
        });
    }, 2000), []);
    useEffect(() => {
        if (refreshIsActive) {
            return scheduleCheckIdleTimer();
        }
        clearTimeout(idleCheckTimerRef.current);
    }, [refreshIsActive]);
    useEffect(() => {
        clearTimeout(refreshTimerRef.current);
        if (refreshIsActive && isAuthorized) {
            scheduleTokenRefresh(shiftExpiredAt(expiredAt));
        }
    }, [expiredAt, isAuthorized, refreshIsActive]);
    useEffect(() => {
        const eventsToListen = [
            'mousemove',
            'keydown',
            'wheel',
            'mousewheel',
            'mousedown',
            'touchstart'
        ];
        broadcastChannel.addEventListener('message', async ({ data }) => {
            switch (data.type) {
                case 'tokenRequested':
                    clearTimeout(refreshTimerRef.current);
                    return;
                case 'tokenUpdate':
                    setAuthorizationHeader(data.token);
                    await dispatch(updateToken({ token: data.token, expiredAt: data.expiredAt }));
                    return;
                case 'syncLogout':
                    setAuthorizationHeader(null);
                    await dispatch(resetAuth());
                    return;
                case 'syncLogin':
                    window.location.replace(locationState?.fromUrl ?? '/');
                    return;
                case 'resetIdle':
                    lastActiveAt.current = new Date();
                    return;
            }
        });
        eventsToListen.forEach((e) => {
            document.addEventListener(e, handleIdleReset, {
                capture: true,
                passive: true
            });
        });
        return () => {
            broadcastChannel.close();
            eventsToListen.forEach((e) => {
                document.removeEventListener(e, handleIdleReset);
            });
        };
    }, []);
    return {
        initTokenSync: () => setRefreshIsActive(true),
        stopTokenSync: () => setRefreshIsActive(false)
    };
};
export const sendSyncLogin = () => broadcastChannel.postMessage({ type: 'syncLogin' });
export const sendSyncLogout = () => broadcastChannel.postMessage({ type: 'syncLogout' });

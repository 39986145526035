import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { CounterpartyLogo } from '@copper/components/Counterparty/CounterpartyNetworkCard/CounterpartyLogo';
import { ExploreNetworkMessage } from '@copper/components/Counterparty/ExploreNetworkMessage';
import { useFetchCounterparties } from '@copper/hooks/useFetchCounterparties';
import { FormControl, FormLabel, Select } from '@copper/ui-kit';
import s from './CounterpartySelect.module.css';
const CounterpartySelect = ({ control, errorMessage, name, testId = 'select-counterparty', view = 'tool', label = 'Network', placeholder = 'Select organisation', counterpartyType, operations, tooltipInfo }) => {
    const { field: { onChange, value } } = useController({
        name,
        control
    });
    const [searchQueryString, setSearchQueryString] = useState('');
    const { isSearching, counterparties } = useFetchCounterparties({
        searchQueryString,
        isConnected: true,
        counterpartyType: counterpartyType,
        operations: operations
    });
    const handleOnChange = (value) => {
        onChange(value);
        setSearchQueryString('');
    };
    const emptyString = () => (_jsxs(_Fragment, { children: [_jsx("p", { children: "No results found" }), _jsx("p", { children: "Try another search" })] }));
    return (_jsxs(FormControl, { errorMessage: errorMessage, children: [_jsx(FormLabel, { view: view, tooltipView: view, tooltipInfo: tooltipInfo ?? '', children: label }), _jsx(Select, { view: view, placeholder: placeholder, searchPlaceholder: "Search by name or Network ID", items: isSearching ? [] : counterparties, getIcon: ({ counterpartyName, imageId }) => (_jsx(CounterpartyLogo, { imageId: imageId, size: "2xs", counterpartyName: counterpartyName })), getTopRow: ({ counterpartyName }) => counterpartyName, getBottomRow: ({ networkTag }) => `@${networkTag}`, onChange: handleOnChange, selected: value, getKey: ({ counterpartyId }) => counterpartyId, searchFields: ['counterpartyName', 'networkTag'], emptySearchString: emptyString(), testId: testId, onSearch: (value) => setSearchQueryString(value), emptyDataString: _jsx(ExploreNetworkMessage, {}), reversedStyles: true, isLoading: isSearching, renderMidSection: () => _jsx("div", { className: s.selectMidSection, children: "My connections" }) })] }));
};
export { CounterpartySelect };
